import React from 'react';
import {useFormContext, Controller} from "react-hook-form";
import markdown from '../../../lib/markdown-parser';
import DatePicker from 'react-datepicker'

const DynamicControl = ({fieldData, countryData}) => {
	const {register, watch, setValue, control} = useFormContext();
	const emailLowerCase = (e) => setValue(e.target.name, e.target.value.toLowerCase());

	const field = fieldData;
	let defaultValue = null;

	let regExFlag = field.regExpFlags;

	if (regExFlag) {
		regExFlag = regExFlag.join('');
	}

	let config = {
		required: field.required,
		pattern: new RegExp([field.validationRegex].toString(), regExFlag)
	};


	switch (field.type) {
		case "text":
			return (
				<>
					<input
						type="text"
						name={field.apiField}
						autoComplete="off"
						{...register(field.apiField, config)}
						defaultValue={defaultValue}
					/>
				</>
			);

		case "email":
			return (
				<input
					type="text"
					name={field.apiField}
					{...register(field.apiField, config)}
					defaultValue={defaultValue}
					onChange={emailLowerCase}
				/>
			);
		case "password":
			if (field.compareField) {
				config.validate = (val) => {
					if (watch(field.compareField) !== val) {
						return field.validationErrorMessage;
					}
				};
			}
			return (
				<input
					type="password"
					name={field.apiField}
					autoComplete="off"
					{...register(field.apiField, config)}
					defaultValue={defaultValue}
				/>
			);

		case 'date':
			return (
				<Controller control={control} name={field.apiField} render={({field: {onChange, value}}) => {
					return <DatePicker
						type="date"
						name={field.apiField}
						maxDate={new Date(new Date().setFullYear(new Date().getFullYear()-16))}
						minDate={new Date(new Date().setFullYear(new Date().getFullYear()-120))}
						selected={value}
						showMonthDropdown
						showYearDropdown
						dropdownMode='select'
						dateFormat={'dd/MM/yyyy'}
						onChange={(date) => onChange(date)}
						placeholderText={field.placeholder}
						onKeyDown={(e) => {
							e.preventDefault();
						}}
					/>
				}} />


			);


		case "textarea":
			return (
				<textarea className="text-area" />
			);

		case "number":
			return (
				<input
					type="number"
					name={field.apiField}
					{...register(field.apiField, config)}
					defaultValue={defaultValue}
				/>
			);

		case "checkbox":
			return (
				<>
					<div className="checkbox-wrapper"><input
						type="checkbox"
						value={(e) => e.target.checked === true}
						name={field.apiField}
						className="checkbox-custom"
						{...register(field.apiField, config)}

					/>
						<label htmlFor={field.apiField}
							dangerouslySetInnerHTML={{ __html: markdown(field.miniContent) }} />
					</div>
				</>

			);
		case "singleCheckbox":
			return (
				<>
				{
					field.options &&
					field.options.map((option, i) => (
					<>
						<div className="checkbox-wrapper">
							<input
								type="checkbox"
								value={i}
								name={field.apiField}
								onClick={(e) => {
									setValue(e.target.name, e.target.value, { shouldValidate: true });
								}}
								{...register(field.apiField, config)}
								className="checkbox-custom"
							/>
							<label>{option}</label>
							<br/>
						</div>
					</>
					))
				}
				</>
			);
		case "radio":
			return (
				<>
					<div className="radio-button-group">
					{
						field.options &&
						field.options.map((option) => (
							<div key={field.contentfulId + '_' + option}
								className="radio-button-wrapper"><input
								type="radio"
								value={option}
								name={field.apiField}
								{...register(field.apiField, config)}
								className="radio-button"
							/> <label htmlFor={field.apiField}>{option}</label></div>
						))
					}
					</div>
				</>
			);
		case "select": {
			return (
				<select
					{...register(field.apiField, config)}
					name={field.apiField}
					id={field.apiField}
					defaultValue={defaultValue}
				>
					{
						field.options &&
						field.options.map((o, index) => (
							<option
							key={index}
							value={o}
							className="select-option"
							>
								{o}
							</option>
						))
					}

				</select>
			);
		}

		case "country": {
			return (
				<select
					{...register(field.apiField, config)}
					name={field.apiField}
					id={field.apiField}
					defaultValue={defaultValue}
				>
					<option
						value=""
						className="select-option"
					>
						-- Please select --
					</option>
					{
						countryData &&
						countryData.map((o, index) => (
							<option
							key={index}
							value={o.iso}
							className="select-option"
							>
								{o.name}
							</option>
						))
					}

				</select>
			);
		}
		case "ul":
			return (
				<ul>
					{
						field.options &&
						field.options.map((o) => (
							<li>{o}</li>
						))
					}
				</ul>
			);
		default:
			return <></>;
	}
};

export default React.memo(DynamicControl);
