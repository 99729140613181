import React from 'react';
import './DevModule.scss';
import PropTypes from 'prop-types';
import pageComponentsList from '../../containers/ComponentsList';
import FanZoneHero from './FanzoneHero';
import FanZoneActivities from './FanzoneActivities';
import FanZoneRewards from './FanzoneRewards';
import FanZoneNFTList from './FanzoneNFTList';
import FanZoneBadges from './FanzoneBadges';
import FanZoneCTWidget from './FanzoneCTWidget';
import { Helmet } from 'react-helmet';


function DevModule(props) {
	const DevModuleEnum = {
		FanzoneHero: 'fanzoneHero',
		FanzoneActivities: 'activities',
		FanzoneRewards: 'rewards',
		FanzoneNFTList: 'nfts',
		FanzoneBadges: 'badges',
		FanZoneCTWidget: 'ctwidget'
	};

	return (
		<div>
			{window.CrowdTwist != undefined? '':
				<Helmet>
					<script id="ct_api_js" src="https://resources.crowdtwist.com/v259/widgets/javascripts/widgets-sdk.0.3.1.js"></script>
				</Helmet>
			}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneHero &&
			<FanZoneHero {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneActivities &&
			<FanZoneActivities {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneRewards &&
			<FanZoneRewards {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneNFTList &&
			<FanZoneNFTList {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanzoneBadges &&
			<FanZoneBadges {...props}/>}
			{pageComponentsList.devModuleList[props.data.externalSourceType] && props.data.externalSourceType === DevModuleEnum.FanZoneCTWidget &&
			<FanZoneCTWidget {...props}/>}
		</div>
	)
}

DevModule.propTypes = {
	data: PropTypes.object,
};

DevModule.defaultProps = {
	data: {},
};

export default DevModule
