import {
	ActionBanner,
	TeamListing,
	PromoContent,
	EditorialText,
	Gallery,
	MemoizedAccordion as Accordion,
	EditorialProfile as Person,
	EditorialSummaryContainer as SummaryBox,
	MemoizedExternalVideo as ExternalVideo,
	EditorialCTA as EditorialCta,
	EditorialCTAContainer,
	EditorialHeader,
	MemoizedIFrameContent as IFrameContent,
	MemoizedAdvertising as Advert,
	RaceHighlight,
	MemoizedHeroBanner as HeroBanner,
	NextAndLastRace,
	FeaturedContent,
	NewCarouselContainer,
} from '@sailgp/sailgp-shared-components';

import { ContentHubContainer } from '../../components/ContentHub/ContentHubContainer';
import { ContentHubMiniContainer } from '../../components/ContentHubMini/ContentHubMiniContainer';
import { TabContainer } from '../../components/Tabs/TabsContainer';
import { TeamContainer as Team } from '../../components/Team/teamContainer';
import LeaderboardContainer from '../LeaderboardContainer/LeaderboardContainer';
import HeroContainer from '../../components/HeroSection/HeroContainer';
import ScoreCriteria from '../../components/ScoreCriteria/ScoreCriteria';
import ScoreCategoryContainer from '../../components/ScoreCategory';
import ILImageGrid from '../../components/ILImageGrid/ILImageGrid';
import Stats from '../../components/Stats';
import ILSectionCTA from '../../components/ILSectionCTA/ILSectionCTA';
import FormGroup from '../../components/FormGroup';
import FanZoneActivities from '../../components/DevModule/FanzoneActivities';
import FanZoneHero from '../../components/DevModule/FanzoneHero';
import FanZoneRewards from '../../components/DevModule/FanzoneRewards';
import FanZoneNFTList from '../../components/DevModule/FanzoneNFTList';
import FanZoneBadges from '../../components/DevModule/FanzoneBadges';
import FanZoneCTWidget from '../../components/DevModule/FanzoneCTWidget';
import DevModule from '../../components/DevModule';
import PassphraseGrid from '../../components/PassphraseGrid/PassphraseGrid';
import ILLeaderboard from '../../components/ILLeaderboard';
import TeamMatchup from '../../components/ILTeamMatchup';
import CustomTextContainer from '../../components/CustomTextContainer';
import TeamsSelection from '../../components/TeamsSelection';
import DockCTA from '../../components/DockCTA/DockCTA';
import { ConfigurableGridContainer } from '../../components/ConfigurableGridContainer';
import {CtsLogo} from '../../components/CTSLogo';
import NearWallet from '../../components/NearWallet'
import ImageContainer from '../ImageContainer/ImageContainer';
import LeaderboardTabContainer from '../LeaderboardTabContainer/LeaderboardTabContainer';
import LiveFeed from './../../components/LiveFeed';
import RaceSchedule from './../../components/RaceSchedule';
import RaceEvent from './../../components/RaceEvent';
import BroadcastVideo from './../../components/BroadcastVideo';
import Table from './../../components/Table/TableContainer';
import HowToWatch from './../../components/HowToWatch/HowToWatchContainer';
import { RaceCalenderContainer as RaceEventListing } from './../../components/RaceCalendar/RaceCalenderContainer';

const allPageComponentsList = {
	NextAndLastRace,
	EditorialText,
	SummaryBox,
	Leaderboard: LeaderboardContainer,
	HeroBanner,
	LiveFeed,
	RaceSchedule, //todo legacy code possibly and can be removed in future releases.
	TabContainer,
	Gallery,
	Accordion,
	Team,
	ContentHub: ContentHubContainer,
	FeaturedContentContainer: FeaturedContent,
	Image: ImageContainer,
	Person,
	MiniHub: ContentHubMiniContainer,
	NanoHub: ContentHubMiniContainer,
	RaceEventListing,
	Advert,
	ExternalVideo,
	PromoContent,
	IFrameContent,
	RaceEvent,
	TeamListing,
	RaceHighlight,
	RaceLiveStream: BroadcastVideo,
	EditorialCta: EditorialCTAContainer,
	Table,
	HowToWatch,
	ActionBanner,
	Hero: HeroContainer,
	ScoreCategory: ScoreCategoryContainer,
	ScoreCriteria,
	Stats,
	IlImageGrid: ILImageGrid,
	IlSectionCta: ILSectionCTA,
	ConfigurableGrid: ConfigurableGridContainer,
	DevModule,
	FormGroup,
	IlLeaderboard: ILLeaderboard,
	CtsLogo,
	CustomTextContainer,
	TeamsSelection,
	TeamMatchup: TeamMatchup,
	DockCTA,
	NewCarousel: NewCarouselContainer,
	NearWallet,
	LeaderboardTabContainer
};

const editorialComponentsList = {
	HeroBanner,
	RaceLiveStream: BroadcastVideo,
	Table,
	RaceSchedule,
	Leaderboard: LeaderboardContainer,
	RaceEvent,
	RaceHighlight,
	Advert,
	Image: ImageContainer,
	IFrameContent,
	RaceEventListing,
	ActionBanner,
	PromoContent,
	EditorialHeader,
	EditorialText,
	SummaryBox,
	ExternalVideo,
	Gallery,
	Accordion,
	Team,
	FeaturedContentContainer: FeaturedContent,
	Person,
	NanoHub: ContentHubMiniContainer,
	EditorialCta: EditorialCTAContainer,
	MiniHub: ContentHubMiniContainer,
	TabContainer,
	ConfigurableGrid: ConfigurableGridContainer,
	ScoreCriteria,
	Hero: HeroContainer,
	ScoreCategory: ScoreCategoryContainer,
	TeamMatchup: TeamMatchup,
	FormGroup,
	CtsLogo,
	CustomTextContainer,
	TeamsSelection,
	DockCTA,
	NewCarousel: NewCarouselContainer,
	NearWallet,
	LeaderboardTabContainer
};

const tabComponentsList = {
	Accordion,
	EditorialText,
	Person,
	SummaryBox,
	EditorialCta,
	Team,
	Gallery,
	MiniHub: ContentHubMiniContainer,
	NanoHub: ContentHubMiniContainer,
	ConfigurableGrid: ConfigurableGridContainer,
	DevModule,
	CustomTextContainer,
	NearWallet,
	NewCarousel: NewCarouselContainer,

};

const configurableGridComponentsList = {
	EditorialText,
	Image: ImageContainer,
	FormGroup,
	DevModule,
	ActionBanner,
	CustomTextContainer,
	Accordion,
	EditorialCta: EditorialCTAContainer,
	NearWallet,
	Leaderboard: LeaderboardContainer,
	LeaderboardTabContainer
};


const devModuleList = {
	fanzoneHero: FanZoneHero,
	activities: FanZoneActivities,
	rewards: FanZoneRewards,
	nfts: FanZoneNFTList,
	badges: FanZoneBadges,
	passphrase: PassphraseGrid,
	ctwidget:FanZoneCTWidget,
	otherComponents: {
		FormGroup
	},
}

export default {
	allPageComponents: allPageComponentsList,
	editorial: editorialComponentsList,
	tabComponents: tabComponentsList,
	configurableGridComponents: configurableGridComponentsList,
	devModuleList: devModuleList
};
