export const getCategoryLeaderboard = (data, categoryCode) => {
	const { CategoryItems } = data;
	const foundCategory = CategoryItems.find(({ CategoryCode }) => CategoryCode === categoryCode);

	if (foundCategory) {
		const { CategoryName, CategoryDescription } = foundCategory;

		return foundCategory.TeamScores.map(teamScore => {
			return {
				...teamScore,
				CategoryName,
				CategoryDescription
			};
		});
	} else return null;
};
export const getCategories = (data) => {
	const { CategoryItems } = data;
	if (CategoryItems.length === 0) return null;

	return CategoryItems.map(({ CategoryCode, CategoryName }) => {
		return { value: CategoryCode, label: CategoryName };
	});
};

export const getPositionLabel = (position) => {
	switch (position) {
		case 0:
			return '1st';
		case 1:
			return '2nd';
		case 2:
			return '3rd';
		default:
			return '';
	}
};
export const getTeam = (listOfTeams, abbr) => listOfTeams.find((team) => team.code.toLowerCase() === abbr.toLowerCase());

export const getFormattedDate = (timestamp) => {
	const date = new Date(timestamp);
	const month = date.getMonth() + 1;
	const day = date.getDate();
	const year = date.getFullYear();
	return `${month}/${day}/${year}`;
};

export const getDefaultSeason = (str) => {
	if (!str || typeof str !== 'string' || str.length === 0) return null;
	const splitStr = str.split('|');
	return Array.isArray(splitStr) ? { label: splitStr[0].trim(), value: splitStr[1].trim() } : null;
};

export const getPartnerData = (team) => {
	const { partners } = team;
	let partnersGroup = { partners: [] };
	if (partners && partners.length > 0) {
		partnersGroup = partners.find(i => i.contentfulType.toLowerCase() === 'partnergroup') || partnersGroup;
	}
	let firstPartner = {};
	if (partnersGroup && partnersGroup.partners && partnersGroup.partners.length > 0) {
		firstPartner = partnersGroup.partners[0];
	}
	return {
		message: partnersGroup.message || '',
		slogan: partnersGroup.slogan || '',
		type: 'Race for the Future Partner',
		logo: firstPartner.logo || ''
	};
};

export const getPercentage = (obtained, total) => total > 0 ? Math.round((obtained / total) * 100) : 0;


