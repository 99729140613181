import React, { useContext } from 'react';
import { EditorialImage as Image } from '@sailgp/sailgp-shared-components';
import SettingsContext from '../../context/SettingsContext';

const ImageContainer = ({data}) => {
	const {pageSettings: {serverSettings}} = useContext(SettingsContext)
	const isAppView= serverSettings && serverSettings.contentOnly
	return (
		<div>
			<Image data={data} className={`${data.webOnly && isAppView ? `c-editorial-image__web-only` : ''}`}/>
		</div>
	);
};

export default ImageContainer;
