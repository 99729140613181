import React, {useContext} from 'react';
import {withRouter} from 'react-router';
import {MemoizedLeaderboard as Leaderboard} from '@sailgp/sailgp-shared-components';
import SettingsContext from '../../context/SettingsContext';
import DBLeaderboard from '../../components/DBLeaderboard';
import DBLeaderboardTabs from '../../components/DBLeaderboardTabs';
import '../../components/DBLeaderboard/DBLeaderboard.scss';

const LeaderboardContainer = (props) => {
	const {pageSettings: {serverSettings}} = useContext(SettingsContext)
	const isWidgetView = serverSettings && serverSettings.widget
	
	if (props.data.useDatabase) {
		if (props.data.leaderBoardType === 'All event leaderboards') {
			return (<DBLeaderboardTabs {...props} widget={isWidgetView}/>)
		}
		return (<DBLeaderboard {...props} widget={isWidgetView}/>)
	} else {
		return (<Leaderboard  {...props} widget={isWidgetView}/>)
	}
	
}

export default withRouter(LeaderboardContainer);
