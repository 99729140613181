import React, {Component} from 'react';
import '@styles-global';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Helmet} from 'react-helmet';
import {CookiesProvider, withCookies, Cookies} from 'react-cookie';
import fetch from 'isomorphic-fetch';
import {ToastContainer} from 'react-toastify';
import {ScreenLoadingOverlay} from '@sailgp/sailgp-shared-components';
import UserProvider from '../../providers/UserProvider';
import UserContext from '../../context/UserContext';
import {verifyAccessToken, submitLogout} from '../../utils/submitDockForm';
import {loginPageURL, dockLandingURL, registerURL, dashBoardURL} from '../../lib/content-api-utils';
import SettingsProvider from '../../providers/SettingsProvider';
import Logger from './../../lib/logger';
import PageProvider from './../../providers/PageProvider';
import PageContainer from './PageContainer';
import AdditionalDataModal from '../../components/AdditionalDataModal/AdditionalDataModal';

const logger = Logger.get('AuthPageContainer');

class MainPageContainer extends Component {

	state = {
		loginStatus: false
	};

	shouldComponentUpdate(nextProps) {
		// This conndition here will prevent the page to re-render into a loading state.
		// In case we need to have page transitions, this will have to be removed.
		if (nextProps.loading === true) {
			return false;
		}

		return true;
	}

	async getCountry(geo) {
		const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${geo.coords.latitude}&longitude=${geo.coords.longitude}&localityLanguage=en`;
		try {
			const res = await fetch(url);
			const json = await res.json();
			if (json.countryCode.toLowerCase() === 'jp') {
				json.countryCode = 'ja';
			}
			return json.countryCode.toLowerCase();
		} catch (error) {
			logger.error(error);
		}
		return '';
	}

	async verifyLogin(pageLoginStatus) {
		const search = window.location.search;

		const pagePath = window.location.pathname
		//Mobile token to web token :Start
		if (search.length != 0 && pagePath == '/user/auth/') {

			//Mobile token to web token :end

		} else {
			const loginToken = localStorage.getItem('isLoggedIn');
			const pageURL = this.props.data.content.url;
			if (pageURL !== loginPageURL()) {
				sessionStorage.removeItem('logout-url');
			}
			if (pageLoginStatus == true || loginToken == 'true') {
				const userResponse = await verifyAccessToken()
				if (userResponse.status !== undefined && userResponse.status == true) {
					this.setState({loginStatus: true});
					if (pageURL == dockLandingURL() || pageURL == registerURL() || pageURL == loginPageURL()) {
						location.assign(dashBoardURL());
					}

				} else {
					if (pageURL !== dockLandingURL()
						&& pageURL !== registerURL()
						&& pageURL !== loginPageURL()) {
						try {
							sessionStorage.setItem('logout-url', encodeURIComponent(location.href));
						} catch (e) {
							// ignore
						}
					}
					localStorage.removeItem('isLoggedIn');
					localStorage.removeItem('userData');
					await submitLogout();


					//todo if code block temp code for nearcon 23. needs to be removed Nov 23. Replace with below line
					//	location.assign(loginPageURL());
					if (search.includes('codesubmit?code=fitnesschallenge')) {
						location.assign('/dock/dubai-fitness-challenge-23/?contentonly=true');
					} else {
						location.assign(loginPageURL());
					}


				}
			}
		}
	}

	setCountry(geo, altVersions) {
		this.getCountry(geo)
			.then(countryLoc => {
				const availablePage = altVersions.filter(lang => lang.language === countryLoc);
				localStorage.locationAuthorised = 'true';
				if (availablePage && availablePage[0].language === 'ja' && !window.location.hash && window.location.pathname === '/') {
					window.location.href = availablePage[0].url;
				}
				return false;
			})
			.catch(error => {
				logger.error(error);
			});
	}

	componentDidMount() {
		const {data} = this.props;
		this.verifyLogin(data.content.requiresLogin);

		const targetLanguage = data.content.language === 'ja' ? 'ja' : 'en';

		// Unwanted language - see if wanted exists
		const altVersions = data.content.alternateLanguageVersions;
		if (targetLanguage !== data.content.language && data.content.alternateLanguageVersions.length > 0) {
			for (let i = 0; i < altVersions.length; i++) {
				const o = altVersions[i];
				if (o.language === targetLanguage) {
					window.location.href = o.url;
				}
			}
		}

		if (localStorage && !localStorage.locationAuthorised && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				geo => {
					this.setCountry(geo, altVersions);
				},
				() => {
					const coordinatesLondon = {
						coords: {
							latitude: '51.509865',
							longitude: '-0.118092'
						}
					};
					this.setCountry(coordinatesLondon, altVersions);
				}
			);
		} else {
			localStorage.locationAuthorised = 'true';
			logger.error('Geolocation not supported');
		}
	}

	render() {
		const {loading, error, data} = this.props;
		if (loading) return <ScreenLoadingOverlay active={loading}/>;
		if (error) return 'Something went wrong :(';

		if (data) {
			const pageCanonicalUrl = data.content.canonicalUrl || '';
			const seoIndexingControls = _.get(data, 'content.seoIndexingControls', false);

			return (
				<UserProvider pageLogin={this.state.loginStatus}>
					<SettingsProvider settings={data.siteSettings} serverSettings={data.serverSettings}>
						<PageProvider currentUrl={data.content.url} languages={data.languages}
									  currentLanguage={data.content.language}>
							<CookiesProvider>
								<Helmet>
									<title>{`${_.get(data, 'content.seoMetaTitle', _.get(data, 'siteSettings.defaultOpenGraphTitle'))} | SailGP`}</title>
									{pageCanonicalUrl.length &&
										<link rel="canonical" href={pageCanonicalUrl}/>
									}

									<meta name="description"
										  content={_.get(data, 'content.seoMetaDescription', _.get(data, 'siteSettings.defaultMetaDescription'))}/>

									{data.languages.map((langItem, index) => langItem.hreflang ?
										<link rel="alternate" href={`https://sailgp.com${data.content.url}`}
											  hrefLang={langItem.code} key={index}/> : null)}

									<meta data-react-helmet="true" property="og:locale" content={data.locale}/>
									<meta data-react-helmet="true" property="og:type" content="website"/>
									<meta data-react-helmet="true" property="og:site_name" content="SailGP"/>

									<meta property="og:url" content={`https://sailgp.com${data.content.url}`}/>
									<meta property="og:title"
										  content={_.get(data, 'content.seoOpenGraphTitle', _.get(data, 'siteSettings.defaultOpenGraphTitle'))}/>
									<meta property="og:description"
										  content={_.get(data, 'content.seoOpenGraphDescription', _.get(data, 'siteSettings.defaultOpenGraphDescription'))}/>
									<meta property="og:image"
										  content={`https:${_.get(data, 'content.seoOpenGraphImage.file.url', _.get(data, 'siteSettings.defaultOpenGraphImage.file.url'))}`}/>
									<meta property="og:image:width"
										  content={_.get(data, 'content.seoOpenGraphImage.file.details.image.width', _.get(data, 'siteSettings.defaultOpenGraphImage.file.details.image.width'))}/>
									<meta property="og:image:height"
										  content={_.get(data, 'content.seoOpenGraphImage.file.details.image.height', _.get(data, 'siteSettings.defaultOpenGraphImage.file.details.image.height'))}/>

									<meta name="twitter:card" content="summary_large_image"/>
									<meta name="twitter:site" content={data.siteSettings.twitterHandle || '@sailgp'}/>
									<meta name="twitter:creator"
										  content={data.siteSettings.twitterHandle || '@sailgp'}/>
									<meta name="twitter:title"
										  content={_.get(data, 'content.seoOpenGraphTitle', _.get(data, 'siteSettings.defaultOpenGraphTitle'))}/>
									<meta name="twitter:description"
										  content={_.get(data, 'content.seoOpenGraphDescription', _.get(data, 'siteSettings.defaultOpenGraphDescription'))}/>
									<meta name="twitter:image"
										  content={`https:${_.get(data, 'content.seoOpenGraphImage.file.url', _.get(data, 'siteSettings.defaultOpenGraphImage.file.url'))}`}/>
									<meta name="twitter:image:alt"
										  content={_.get(data, 'content.seoOpenGraphImage.title', _.get(data, 'siteSettings.defaultOpenGraphImage.title'))}/>
									{seoIndexingControls &&
										<meta name="robots" content={seoIndexingControls.join(', ')}/>
									}

								</Helmet>

								<UserContext.Consumer>
									{({isLoggedIn, logIn, logOut, user, deleteAccount}) => (
										data.content.requiresLogin ? (
											isLoggedIn &&
												<PageContainer {...this.props} isLoggedIn={isLoggedIn} logIn={logIn}
															   logOut={logOut} user={user} deleteAccount={deleteAccount}/>
										)
											:
											(<PageContainer {...this.props} isLoggedIn={isLoggedIn} logIn={logIn}
												logOut={logOut} user={user} deleteAccount={deleteAccount}/>)
									)}
								</UserContext.Consumer>
								<ToastContainer
									position="top-center"
									autoClose={2000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
								/>
								<AdditionalDataModal/>
							</CookiesProvider>
						</PageProvider>
					</SettingsProvider>
				</UserProvider>
			);
		}
		return null;
	}
}

MainPageContainer.contextType = UserContext
MainPageContainer.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.object,
	data: PropTypes.object,
	cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default withRouter(withCookies(MainPageContainer));
