import React from 'react';
import './DockCTA.scss'
import PropTypes from 'prop-types';

const DockCTA = ({ label, onClick, data, ...props}) => {
	let isDisabled;
	let type;
	if(props) {
		isDisabled = props.disabled;
		type = props.type;
	}
	const openInNewTab = () => {
		if (data) {
			if (data.openInSameTab) {
				return {
					href: data.url,
					rel: 'noopener noreferrer',
				}
			} else {
				return {
					href: data.url,
					target: '_blank',
					rel: 'noopener noreferrer',
				}
			}
		}

	}

	return (
		<button className={`c-dock-cta c-dock-cta${isDisabled ? '--disabled' : ''}`} onClick={onClick} type={type} disabled={isDisabled} {...openInNewTab()}>
			<p className='c-dock-cta__label'>{label || data.label}</p>
			<img className='c-dock-cta__icon' src={isDisabled ? '/images/continue-icon-disabled.svg' : '/images/continue-icon.svg'} alt=''/>
		</button>
	);
};

DockCTA.propTypes = {
	data: PropTypes.object,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
}


export default DockCTA;
