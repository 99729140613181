import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import PageContext from '../../context/PageContext';

class PageProvider extends React.Component {
	constructor(props) {
		super(props);

		const { currentUrl, currentLanguage, languages } = this.props;

		// get current language string via content service, return full object from languages array
		const selectedLanguage = languages.filter(e => e.code === currentLanguage)[0];
		// TODO: Using a ref might be better
		let sideDrawerEl;

		this.state = {
			isSectionNavPanelOpen: false,
			isSideDrawerOpen: false,
			isLanguageSelectOpen: false,
			activeLanguage: selectedLanguage || languages[0],
			renderRedirect: undefined,
			isSecondaryNavOpen: false,
			clickCounter: 0,
			// TODO: This has been removed from all the other components. (check this commit id to see from where)
			// The reason behind it was that a ReactRouter redirect was not triggering a data fetch again.
			// Using plain A tags fixed the issue. But this means there is no Context value set for the current lang.
			// Either refactor this to make it work without breaking the language switch data fetch, or remove it.
			// https://github.com/ReactTraining/react-router/issues/1982#issuecomment-172228308
			selectLanguage: activeLanguage => {
				const redirectTo = activeLanguage.url;
				const shouldRedirect = currentUrl !== redirectTo;
				this.setState(
					{
						activeLanguage,
						renderRedirect: shouldRedirect && redirectTo ? redirectTo : undefined
					},
					() => this.setState({ renderRedirect: undefined })
				);
			},
			closeLanguageSelect: () => {
				this.setState({
					isLanguageSelectOpen: false
				});
			},
			toggleSectionNavPanelOpen: () => {
				const isSectionNavPanelOpen = !this.state.isSectionNavPanelOpen;
				this.setState({
					isSectionNavPanelOpen: isSectionNavPanelOpen,
					isSideDrawerOpen: false
				});
			},
			toggleSideDrawerOpen: (closeSecondary) => {
				const isSideDrawerOpen = !this.state.isSideDrawerOpen;
				if (sideDrawerEl === undefined) {
					sideDrawerEl = document.querySelector('.c-side-drawer');
				}

				if (isSideDrawerOpen) {
					disableBodyScroll(sideDrawerEl, { allowTouchMove: () => true });
				} else {
					enableBodyScroll(sideDrawerEl);
				}

				if(closeSecondary) this.state.closeSecondaryNav()

				this.setState({
					isSideDrawerOpen: isSideDrawerOpen,
					isSectionNavPanelOpen: false
				});
			},
			toggleLanguageSelectOpen: event => {
				event.preventDefault();
				const isLanguageSelectOpen = !this.state.isLanguageSelectOpen;
				this.setState({
					isLanguageSelectOpen: isLanguageSelectOpen,
					isSectionNavPanelOpen: false
				});
			},
			closeSecondaryNav: () => {
				
				this.setState({
					isSecondaryNavOpen: false,
					isSectionNavPanelOpen: false
				});
			}
		};
	}

	render() {
		return (
			<PageContext.Provider value={this.state}>
				{this.state.renderRedirect ? <Redirect push to={{ pathname: this.state.renderRedirect }} /> : null}
				{this.props.children}
			</PageContext.Provider>
		);
	}
}

PageProvider.propTypes = {
	children: PropTypes.any,
	currentUrl: PropTypes.string,
	currentLanguage: PropTypes.string,
	languages: PropTypes.array,
	siteSettings: PropTypes.object,
};

export default PageProvider;
