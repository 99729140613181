import MainPageContainer from '../containers/MainPageContainer';
import getInitialData from './getInitialData';

// https://github.com/expressjs/express/issues/2495
export const pageRegexPath = `/:page([a-z0-9][a-z0-9-/]{0,})`;

const routes = [
	{
		path: pageRegexPath,
		exact: false,
		component: MainPageContainer,
		getInitialData,
	},
	{
		path: '/',
		exact: false,
		component: MainPageContainer,
		getInitialData,
	},
	{
		path: '/index.html',
		exact: true,
		component: MainPageContainer,
		getInitialData,
	},
];

export default routes;
