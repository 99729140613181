import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import pageComponentsList from './../../containers/ComponentsList';
import PageContext from '../../context/PageContext';
import {HubMini} from '@sailgp/sailgp-shared-components';
import fetchNewsAPI from '../ContentHub/api/get-data';
import Logger from '../../lib/logger';
import { prepareAnalytics, virtualPageView } from '../../lib/analytics';
import {fetchPageContent} from '../../utils/fetchPageContent';
import UserContext from '../../context/UserContext';

const logger = Logger.get('HubMini');

export class ContentHubMiniContainer extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			extraItems: [],
			extraItemsNewestPage: 0,
			extraItemsPageSize: 8,
			extraItemsHasNextPage: true,
			drawerOpen: false,
			drawerContentUrl: undefined,
			drawerContentType: undefined,
			loadingContentUrl: undefined,
			pageContent: undefined
		};
		this.handleLoadMore = this.handleLoadMore.bind(this);
		this.handleCloseEditorial = this.handleCloseEditorial.bind(this);
		this.handleCardClick = this.handleCardClick.bind(this);
		this.onLoadingFinished = this.onLoadingFinished.bind(this);
		this.updatePageContent = this.updatePageContent.bind(this);
		this.clearPageContent = this.clearPageContent.bind(this);
	}

	handleLoadMore = async () => {
		const {data} = this.props;

		const filterList = [
			{
				type: 'lang',
				value: data.lang
			},
			{
				type: 'filter',
				value: (data.selectedFilters || [])
					.map(item => item.contentfulId)
					.join(',')
			},
			{
				type: 'type',
				value: (data.selectedTypes || [])
					.join(',')
			},
			{
				type: 'exclude',
				value: data.items
					.map(item => item.contentfulId)
					.join(',')
			},

			{
				type: 'page',
				value: this.state.extraItemsNewestPage + 1
			},
			{
				type: 'perpage',
				value: this.state.extraItemsPageSize
			}
		];
		try {
			const fetchedData = await fetchNewsAPI({filters: filterList});
			this.setState({
				extraItems: [...this.state.extraItems, ...fetchedData.items],
				extraItemsNewestPage: fetchedData.page,
				extraItemsPageSize: fetchedData.perPage,
				extraItemsHasNextPage: fetchedData.hasNextPage
			});
		} catch (error) {
			logger.error(error);
		}
	}

	handleCloseEditorial = () => {
		window.history.pushState(null, null, this.urlPrevLocation);
		this.setState({drawerOpen: false});
	}

	handleCardClick(link, type, prevLocation) {
		this.urlPrevLocation = prevLocation;
		this.setState({
				drawerOpen: true,
				drawerContentUrl: link,
				drawerContentType: type,
				loadingContentUrl: link
			},
			() => {
				virtualPageView({
					'page': link,
				});
			});
	}

	onLoadingFinished() {
		this.setState({
			loadingContentUrl: undefined
		});
	}

	async updatePageContent() {
		try {
			this.setState({
				pageContent: await fetchPageContent(this.state.drawerContentUrl)
			});

		} finally {
			if (this.onLoadingFinished) {
				this.onLoadingFinished();
			}
		}
	}

	clearPageContent() {
		this.setState({
			pageContent: undefined
		});
	}

	render() {
		return (
			<UserContext.Consumer>
				{({isLoggedIn, user:{userId}})=>(
					<PageContext.Consumer>
						{({activeLanguage}) => (
							<HubMini pageComponentsList={pageComponentsList}
									 activeLanguage={{activeLanguage}}
									 data={this.props.data}
									 containerState={this.state}
									 handleLoadMore={this.handleLoadMore}
									 handleCloseEditorial={this.handleCloseEditorial}
									 handleCardClick={this.handleCardClick}
									 onLoadingFinished={this.onLoadingFinished}
									 updatePageContent={this.updatePageContent}
									 clearPageContent={this.clearPageContent}
									 isParentTab={this.props.isParentTab}
									 isLoggedIn={isLoggedIn}
									 userId={userId}
									 prepareAnalytics={prepareAnalytics}
							/>
						)}
					</PageContext.Consumer>
				)}
			</UserContext.Consumer>

		)
	}
}

ContentHubMiniContainer.propTypes = {
	data: PropTypes.object,
	isParentTab: PropTypes.bool,
};
