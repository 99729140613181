import React from 'react';
import PropTypes from 'prop-types';
import Hero from '.';

export default function HeroContainer(props) {
	const { data } = props ;
	return <Hero data={data} />;
}

HeroContainer.propTypes = {
	data: PropTypes.object
};

HeroContainer.defaultProps = {
	data: {}
};
