import React, {useContext, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './FanzoneHero.scss';
import fetch from 'isomorphic-fetch';
import {submitDockForm, submitDockFormNoBody} from '../../../utils/submitDockForm';
import {getContentAPIURL} from '../../../lib/content-api-utils';
import UserContext from '../../../context/UserContext';
import useIsMounted from '../../../hooks/useIsMounted';
import DockAccountOverview from './DockAccountOverview';

function FanZoneHero(props) {
	const [userPrefs, setUserPrefs] = useState({});
	const {updateProfile, logOut, user} = useContext(UserContext);
	const fallBackImgUrl = '/dock/SailGP_TD_Desktop Team Header_SailGP.jpg';
	const [ctUserProfile, setCtUserProfile] = useState();
	const [showContent, setShowContent] = useState(false)

	const initialDataUrl = props.data.submitUrl;
	const isMounted = useIsMounted();

	useEffect(() => {
		let timer;

		const fetchCTUserProfile = async () => {
			try {
				const response = await submitDockForm({}, 'user/ct-user-profile');
				if (response.status === 'success' && isMounted) {
					setCtUserProfile(response.data);
				}

				if (!(response.status === 'error' && response === 'token invalid') && isMounted) {
					timer = setTimeout(fetchCTUserProfile, 15e3);
				}
			} catch (error) {
				// do nothing
			}
		};

		fetchCTUserProfile();

		return () => {
			clearTimeout(timer);
		};
	}, [isMounted]);

	const getFavouriteTeamImgSrc = (favouriteTeamObj) => {
		if (favouriteTeamObj.teamPhoto && favouriteTeamObj.teamPhoto.file) {
			return favouriteTeamObj.teamPhoto.file.url;
		} else if (favouriteTeamObj.nationPatternTiltShift && favouriteTeamObj.nationPatternTiltShift) {
			return favouriteTeamObj.nationPatternTiltShift.file.url;
		} else {
			return fallBackImgUrl;
		}
	};

	const initUserPrefs = async (signal) => {
		try {
			const userDataResponse = await submitDockFormNoBody(initialDataUrl, signal);
			let userPrefsObj = {};

			if (
				userDataResponse.status !== undefined &&
				(userDataResponse.status === 'success' || userDataResponse.status === 'sucess')
			) {
				userPrefsObj = userDataResponse.response;

				if (userPrefsObj.favouriteTeam && userPrefsObj.favouriteTeam !== 'default') {
					try {
						const response = await fetch(getContentAPIURL() + 'teams?incGeneral=true&onlyActive=true', {signal});
						const jsonResponse = await response.json();
						const favouriteTeamObj = jsonResponse.find(
							(obj) => obj.code === userDataResponse.response.favouriteTeam
						);

						if (favouriteTeamObj) {
							userPrefsObj.favouriteTeamObj = favouriteTeamObj;
							userPrefsObj.favouriteTeamImgSrc = getFavouriteTeamImgSrc(favouriteTeamObj);

							if (isMounted) {
								updateProfile(userPrefsObj);
								setUserPrefs(userPrefsObj);
							}
						}
					} catch (error) {
						if (error.name !== 'AbortError' && isMounted) {
							logOut();
							setUserPrefs(null);
						}
					}
				}
			} else if (
				userDataResponse.status !== undefined &&
				userDataResponse.status === 'error' &&
				userDataResponse.redirect === true
			) {
				if (isMounted) {
					logOut();
				}
			} else {
				if (isMounted) {
					setUserPrefs(null);
				}
				userPrefsObj.favouriteTeamImgSrc = fallBackImgUrl;
			}
		} catch (error) {
			if (error.name !== 'AbortError') {
				console.error('Error in initUserPrefs:', error);
			}
		}
	};

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		initUserPrefs(signal);

		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (!userPrefs.favouriteTeamObj) {
			(async () => {
				await initUserPrefs(signal)
			})()
		}
		const showWidget = userPrefs.favouriteTeamObj && ctUserProfile;
		setShowContent(showWidget)
	}, [ctUserProfile, user, userPrefs.favouriteTeamObj])

	function getBannerMedia(content) {
		const {desktopBg, mobileBg, alternateComponentContent} = content;
		const element = {};

		if (desktopBg && desktopBg.file) {
			element.desktopBg = (
				<img
					src={desktopBg.file.url}
					className={`typeActionBanner`}
					alt={desktopBg.file.title || 'Hero image'}
				/>
			);
		}

		if (mobileBg && mobileBg.file) {
			element.mobileBg = (
				<img
					src={mobileBg.file.url}
					className={`typeActionBanner`}
					alt={mobileBg.file.title || 'Hero image'}
				/>
			);
		}

		if (alternateComponentContent && alternateComponentContent.length > 0 && alternateComponentContent[0].image) {
			element.logo = (
				<img
					src={alternateComponentContent[0].image.file.url}
					className={`heroLogo`}
					alt={alternateComponentContent[0].label || 'SailGP The Dock'}
				/>
			);
		}

		return element;
	}

	/* DesktopBG is required, mobileBG is not. This could cause trouble. Also, Alternate Component Content is used to host logo. */
	const BGElement = getBannerMedia(props.data);

	return (
		<div className="fanzone-banner-container">
			<div className="media-container">
				<div className="desktop">
					{BGElement.desktopBg}
				</div>
				<div className="mobile">
					{!BGElement.mobileBg ? BGElement.desktopBg : BGElement.mobileBg}
				</div>
			</div>
			<div className="content-parent content-parent-hero">
				<div className="content-container content-container-outerLogoContainer">
					<div className="logoContainer">
						{BGElement.logo ? BGElement.logo : ''}
					</div>
				</div>
				{showContent &&
					<div
						className={`content-container content-container-outerWidgetContainer`}>
						<div className="headerWidgetShell">
							<div className="teamHeader">
								{userPrefs && (
									<img src={userPrefs.favouriteTeamImgSrc || fallBackImgUrl} alt="Favorite Team"/>
								)}
							</div>

							<DockAccountOverview
								teamColor={userPrefs.favouriteTeamObj.hexColor}
								ctUserProfile={ctUserProfile}
							/>
						</div>
					</div>}
			</div>
		</div>
	);
}

FanZoneHero.propTypes = {
	data: PropTypes.object,
};

FanZoneHero.defaultProps = {
	data: {},
};

export default FanZoneHero;
