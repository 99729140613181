import React from 'react';
import { Cell, Grid, Row } from '../Grid';
import { Img } from '@sailgp/sailgp-shared-components';
import './Stats.scss';
import nanoid from 'nanoid';
import get from 'lodash/get';

import PropTypes from 'prop-types';

const Index = ({ data }) => {
	const { heading, statsList } = data;
	return (
		<section className='c-il-stats'>
			<Grid noHorizontalPadding>
				<Row>
					<Cell columns={12}>
						<h2 className='c-il-stats__heading'>{heading}</h2>
					</Cell>
				</Row>
				<Row>
					{statsList.map(stat => (
						<Cell smCols={2} mdCols={2} lgCols={4} key={nanoid()}>
							<div className='c-il-stats__card'>
								<div className='c-il-stats__card-content'>
									<p className='c-il-stats__card-title'>{stat.heading}</p>
									<p className='c-il-stats__card-subtitle'>{stat.introduction}</p>
								</div>
								{stat.avatarImage && <Img src={get(stat.avatarImage, 'file.url')} imgAlt={stat.title} />
								}
							</div>
						</Cell>
					))}
				</Row>
			</Grid>
		</section>
	);
};

Index.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		stats: PropTypes.array
	})
};
export default Index;
