import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './dropdown.scss';

export default function SelectDropdown({ options, defaultValue ,handleChange, primaryTeam, ...rest }) {
	const onChange = event => {
		handleChange(event);
	};

	return (
		<React.Fragment>
			{options && options.length > 0 ? (
				<Select
					defaultMenuIsOpen={false}
					onChange={onChange}
					maxMenuHeight={600}
					options={options}
					defaultValue={defaultValue || options[0]}
					classNamePrefix='select-data'
					className='section-select'
					value={primaryTeam || defaultValue || options[0] }
					{...rest}
				/>
			) : null}
		</React.Fragment>
	);
}

SelectDropdown.propTypes = {
	options: PropTypes.array,
	handleChange: PropTypes.func
};

SelectDropdown.defaultProps = {
	options: {}
};
