import React from 'react';
import './ILScrollPrompt.scss';
import PropTypes from 'prop-types';
import IconDoubleArrowsLeft from '../Icons/DoubleArrowsLeft';

const ILScrollPrompt = ({ scrollPromptText }) => {
	return <div className='c-il-scroll-prompt'>
		<IconDoubleArrowsLeft />
		<p>{scrollPromptText || 'Scroll For More'}</p>
	</div>;
};

ILScrollPrompt.propTypes = {
	scrollPromptText: PropTypes.string
};

export default ILScrollPrompt;