export const moveToTop = (primaryTeam, categoryData) => {
	const behindTeam = categoryData.filter(cat => cat.TeamName !== primaryTeam);
	const topTeam = categoryData.filter(cat => cat.TeamName === primaryTeam);
	return [...topTeam, ...behindTeam];
};

export const getMatchupData = (teams, categoryTeamsData) => {
	const returnData = [];
	if (teams.length === 0 && categoryTeamsData.length === 0) {
		return returnData;
	}
	categoryTeamsData.map(category => {
		returnData.push({
			CategoryCode: category.CategoryCode,
			CategoryName: category.CategoryName,
			TeamScores: moveToTop(
				teams[0],
				category.TeamScores.filter(teamScore => teams.includes(teamScore.TeamName))
			)
		});
	});

	return returnData;
};
