import React from 'react';
import './ILLeaderboardHeroHeading.scss';
import { Cell, Grid, Row } from '../../Grid';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import ILImageGrid from '../../ILImageGrid/ILImageGrid';

const ILLeaderboardHeroHeading = ({ contentfulData, selectedSeason }) => {
	if (!contentfulData) return null;
	const { headingTextFilled } = contentfulData;

	return <div>
		<Grid noHorizontalPadding noVerticalPadding>
			<Row>
				<Cell mdCols={4} lgCols={8} xlCols={8} align='middle' or='2'>
					<h1 className='c-il-leaderboard__hero-heading'>
						{headingTextFilled} <span
						className='c-il-leaderboard__hero-heading--stroke'>{selectedSeason.label}</span>
					</h1>
				</Cell>
				<Cell mdCols={2} lgCols={4} xlCols={4} align='middle' className='c-il-leaderboard__hero-image'>
					<Img
						src={get(contentfulData.headingImage, 'file.url')}
						imgAlt={get(contentfulData.headingImage, 'title')}
					/>
				</Cell>
			</Row>
		</Grid>
	</div>;


};


ILLeaderboardHeroHeading.propTypes = {
	contentfulData: PropTypes.shape({
		headingTextFilled: PropTypes.string,
		headingTextStroked: PropTypes.string,
		headingImage: PropTypes.object,
	})
};
export default ILLeaderboardHeroHeading;
