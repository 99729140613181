import React, { useEffect, useState } from 'react';
import { Button } from '@sailgp/sailgp-shared-components';
import Toggle from 'react-toggle';
import PropTypes from 'prop-types';
import markdown from '../../lib/markdown-parser';
import './CookieBanner.scss';

const cookieIdEnum = {'NECESSARY COOKIES': 'C0001', 'FUNCTIONAL COOKIES' : 'C0003', 'ANALYTIC COOKIES': 'C0002', 'TARGETING COOKIES': 'C0004'}

const CookieBanner = ({action, data, cookieProvider}) => {
	const [isVisible, setIsVisible] = useState(false)
	const [redirectToCookiesPage, setRedirectToCookiesPage] = useState(false)
	const [isToggle, setIsToggle] = useState(true)
	const [cookieValues, setCookieValues] = useState({'NECESSARY COOKIES': true, 'FUNCTIONAL COOKIES' : true, 'ANALYTIC COOKIES': true, 'TARGETING COOKIES': true})
	const {cookiePageUrl, pageUrl, cookieDescription, continueLabel, learnMoreLabel, cookieMessage, rejectLabel, saveAndCloseLabel} = data
	const isCookiesUrl = pageUrl.includes(cookiePageUrl);
	const cookieName = 'sgp-cp-acceptance';

	useEffect(() => {
		setIsVisible(cookieProvider.cookies[cookieName] !== 'true')
	}, [])


	useEffect(() => {
		if(redirectToCookiesPage && cookiePageUrl) location.assign(cookiePageUrl)
	}, [redirectToCookiesPage])

	const setPolicyAcceptanceCookie = (name, value) => {
		const expires = new Date();
		expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
		cookieProvider.set(name, value, { path: '/', expires: expires });
	}

	const acceptCookies = () => {
		const consentSettings = {
			'ad_storage': 'granted',
			'analytics_storage': 'granted',
			'ad_user_data': 'granted',
			'ad_personalization': 'granted'
		};

		// Save consent settings to local storage
		localStorage.setItem('cookieConsent', JSON.stringify(consentSettings));

		// Save consent settings to cookie
		const expires = new Date();
		expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
		document.cookie = `cookieConsent=${JSON.stringify(consentSettings)}; expires=${expires.toUTCString()}; path=/`;

		setPolicyAcceptanceCookie(cookieName, 'true');
		action(continueLabel, 'Option Selected', null, 'Cookie Banner');
		cookieDescription.forEach(({ heading }) => action(heading, 'accept', cookieIdEnum[heading]))
		setIsVisible(false)
	}

	const rejectCookies = () => {
		const consentSettings = {
			'ad_storage': 'denied',
			'analytics_storage': 'denied',
			'ad_user_data': 'denied',
			'ad_personalization': 'denied'
		};

		// Save consent settings to local storage
		localStorage.setItem('cookieConsent', JSON.stringify(consentSettings));

		// Save consent settings to cookie
		const expires = new Date();
		expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
		document.cookie = `cookieConsent=${JSON.stringify(consentSettings)}; expires=${expires.toUTCString()}; path=/`;

		setPolicyAcceptanceCookie(cookieName, 'true');
		action(rejectLabel, 'Option Selected', null, 'Cookie Banner');
		cookieDescription.forEach(({ heading }) => {
			if(heading === 'NECESSARY COOKIES') action(heading, 'accept', cookieIdEnum[heading]);
			else action(heading, 'reject', cookieIdEnum[heading]);
		})
		setIsVisible(false)
	}

	const eachCookies = (e, cookieHeading) => {
		if(e.target.checked) setCookieValues((prevState) => ({...prevState, [cookieHeading]: e.target.checked}));
		else setCookieValues((prevState) => ({...prevState, [cookieHeading]:false}))
		setIsToggle((prevState) => !prevState)
	}

	const learnMore = () =>{
		action(learnMoreLabel, 'Option Selected', null, 'Cookie Banner') ;
		setRedirectToCookiesPage(true)
	}

	const saveAndClose = () => {
		const consentSettings = {
			'ad_storage': cookieValues['TARGETING COOKIES'] ? 'granted' : 'denied',
			'analytics_storage': cookieValues['ANALYTIC COOKIES'] ? 'granted' : 'denied',
			'ad_user_data': cookieValues['TARGETING COOKIES'] ? 'granted' : 'denied',
			'ad_personalization': cookieValues['TARGETING COOKIES'] ? 'granted' : 'denied'
		};

		// Save consent settings to local storage
		localStorage.setItem('cookieConsent', JSON.stringify(consentSettings));

		// Save consent settings to cookie
		const expires = new Date();
		expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
		document.cookie = `cookieConsent=${JSON.stringify(consentSettings)}; expires=${expires.toUTCString()}; path=/`;

		setPolicyAcceptanceCookie(cookieName, 'true');
		for(const key in cookieValues) {
			if(cookieValues[key]) action(key, 'accept', cookieIdEnum[key])
			else action(key, 'reject', cookieIdEnum[key])
		}
		setIsVisible(false)
	}

	if (isVisible && isCookiesUrl !== true) {
		return (
			<section className="c-cookie-banner">
				<div className="c-cookie-banner_modal">
					<div className="c-cookie-banner_modal_inner">
						<div dangerouslySetInnerHTML={{ __html: markdown(cookieMessage) }} />
					</div>

					<Button variant="primary" className="top-button" onClick={acceptCookies}>
						{continueLabel}
					</Button>
					<Button variant="secondary" className="top-button" onClick={rejectCookies}>
						{rejectLabel}
					</Button>
					<div className="c-cookie-banner_modal_inner">
						<div className="modal-scroll">
							{cookieDescription && cookieDescription.map(item => (
								<div key={item.contentfulId}>
									<div className="cookies-toggle-button">
										<h3>{item.heading}</h3>
										{item.heading === 'NECESSARY COOKIES' ? null : (
											<label>
												<Toggle
													defaultChecked={isToggle}
													onChange={e => {
														eachCookies(e, item.heading);
													}}
													icons={false}
												/>
											</label>
										)}
									</div>
									<div className="cookie-text">
										<p>{item.content}</p>
									</div>
								</div>
							))}
						</div>
					</div>
					{cookieDescription && (
						<Button variant="primary" className="bottom-button" onClick={saveAndClose}>
							{saveAndCloseLabel}
						</Button>
					)}
					<Button
						variant="secondary"
						className={cookieDescription ? 'bottom-button' : 'bottom-button learn-more'}
						onClick={learnMore}
					>
						{learnMoreLabel}
					</Button>
				</div>
				<div className="c-cookie-banner_background"></div>
			</section>
		);
	} else {
		return null;
	}

};

CookieBanner.propTypes = {
	cookieProvider: PropTypes.object,
	data: PropTypes.object,
	action: PropTypes.func
};


export default CookieBanner;
