import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './CustomTextContainer.scss';
import DockCTA from '../DockCTA/DockCTA';
import {EditorialCTA, Img} from '@sailgp/sailgp-shared-components';
import {getReplaceMap} from './replace-map';
import SettingsContext from '../../context/SettingsContext';
import FormGroup from '../FormGroup';

const CustomTextContainer = (props) => {
	const {pageSettings} = useContext(SettingsContext)
	const TagType = props.data.tagType;
	const opts = {};
	const isAppView = pageSettings && pageSettings.serverSettings && pageSettings.serverSettings.contentOnly


	if (props.data.classNames && props.data.classNames.length) {
		if(isAppView && props.data.classNames.includes('page-wrapper-full') && !props.data.classNames.includes('page-wrapper-full__app')) {
			props.data.classNames.push('page-wrapper-full__app');
		}

		if(isAppView && props.data.classNames.includes('near-page-container') && !props.data.classNames.includes('near-page-container__app')) {
			props.data.classNames.push('near-page-container__app')
		}

		if(isAppView && props.data.classNames.includes('near-page-paragraph') && !props.data.classNames.includes('near-page-paragraph__app')) {
			props.data.classNames.push('near-page-paragraph__app')
		}

		opts.className = ['custom-text-container', ...props.data.classNames].join(' ');
	} else {
		opts.className = 'custom-text-container';
	}

	const contentToReplace = getReplaceMap();
	if (props.data.content) {
		Object.keys(contentToReplace).forEach((key) => {
			if (props.data.content.includes(key)) {
				props.data.content = props.data.content.replace(new RegExp(key, 'g'), contentToReplace[key]);
			}
		});
	}

	if(props.data.backgroundImage){
		opts.style={
			'backgroundImage':'url('+props.data.backgroundImage.file.url+')'
		}
	}

	if (props.data.supportInnerHtml) {
		opts.dangerouslySetInnerHTML = { __html: props.data.content };

		return <TagType {...opts} />;
	}


	return <TagType {...opts} >
		{props.data.content}
		{props.data.innerTextComponents &&
		props.data.innerTextComponents.map((comp) => {
			const CompType = comp.contentfulType;
			switch (CompType) {
				case 'CustomTextContainer':
					return <CustomTextContainer data={comp} key={comp.contentfulId}/>;
				case 'DockCTA':
					return <DockCTA data={comp} key={comp.contentfulId}/>;
				case 'EditorialCta':
					return <EditorialCTA {...comp} key={comp.contentfulId}/>;
				case 'FormGroup':
					return <FormGroup data={comp} key={comp.contentfulId}/>;
				case 'Image':
					return <Img src={comp.image.file.url}
								alt={comp.label}
								key={comp.contentfulId}
								className={isAppView &&  comp.appWebViewOnly ? 'app-image' : !isAppView && comp.appWebViewOnly ? 'not-app-image' : ''}/>;
				default:
					return <div key={comp.contentfulId} />;
			}
		})}
	</TagType>;
};

CustomTextContainer.propTypes = {
	data: PropTypes.object
};

export default CustomTextContainer;
