import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconDoubleArrowsLeft = createIconComponent({
	content: (
		<Fragment>
			<path
				d='M14.2929 0.556931C14.6834 0.166407 15.3166 0.166407 15.7071 0.556931C16.0976 0.947456 16.0976 1.58062 15.7071 1.97114L8.41421 9.26404L15.7071 16.5569C16.0976 16.9475 16.0976 17.5806 15.7071 17.9711C15.3166 18.3617 14.6834 18.3617 14.2929 17.9711L6.29289 9.97114C5.90237 9.58062 5.90237 8.94746 6.29289 8.55693L14.2929 0.556931ZM8.29289 0.556931C8.68342 0.166407 9.31658 0.166407 9.70711 0.556931C10.0976 0.947456 10.0976 1.58062 9.70711 1.97114L2.41421 9.26404L9.70711 16.5569C10.0976 16.9475 10.0976 17.5806 9.70711 17.9711C9.31658 18.3617 8.68342 18.3617 8.29289 17.9711L0.292893 9.97114C-0.0976315 9.58062 -0.0976315 8.94746 0.292893 8.55693L8.29289 0.556931Z'
			/>

		</Fragment>
	),
	width: 20,
	height: 20
});

IconDoubleArrowsLeft.displayName = 'IconDoubleArrowsLeft';

export default IconDoubleArrowsLeft;
