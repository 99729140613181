import React, {useEffect,useState} from 'react';
import {Grid} from '../../Grid';
import PropTypes from 'prop-types';

/*
	instances a CT Widget
	expects a paramater input with widget ID and data-src, separated with a pipe | character

	todo: add login check API-Out
*/



function FanZoneCTWidget(props) {
	const [widgetParams,setWidgetParams] = useState(props.data.parameters && props.data.parameters.split("|"));
	const [childKey, setChildKey] = useState(1);

	useEffect(() => {
		setWidgetParams(props.data.parameters && props.data.parameters.split("|"));
		setChildKey(prev => prev + 1);

		if(window.CrowdTwist != null){
			for(var i=0; i<window.CrowdTwist.Widget._widgets.length; i++){
				window.CrowdTwist.Widget.removeWidget(window.CrowdTwist.Widget._widgets[0]); 
			}

			setTimeout(function(){
				window.CrowdTwist.loadWidgets();
			}, 250);
		}
	}, [props.data.parameters]);

	return (
		<div className='ct-widget-container' key={childKey}>
			<div data-wid={widgetParams[0]} >
				<div data-key={childKey} className='ct-widget'
						data-widget-id={widgetParams[0]}
						data-src={widgetParams[1]}
				>&nbsp;</div>
			</div>
		</div>
	)
}


FanZoneCTWidget.propTypes = {
	data: PropTypes.object,
};

FanZoneCTWidget.defaultProps = {
	data: {},
};

export default FanZoneCTWidget
