import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../context/UserContext';
import {submitLogout, submitUserDelete} from '../../utils/submitDockForm';
import {loginPageURL} from '../../lib/content-api-utils';

const UserProvider = ({children, pageLogin}) => {

	let userDetailKey = "userData";
	const mockUser = {
		userId: '',
		userPermissionLevel: 1,
		avatar: '',
		firstName: '',
		lastName: '',
		dateOfBirth: '',
		emailAddress: '',
		favouriteTeam: '',
		nearAccountID: '',
		hasNearAccount: '',
		nearAccountType: '',
		nearDownloadDate: '',
		fanzoneSignupDate: '',
		fanzoneModifiedDate: '',
		gender: ''
	};

	const [user, setUser] = useState(mockUser);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		if (pageLogin == true) {
			let userDetails = localStorage.getItem(userDetailKey);
			logIn(JSON.parse(userDetails));
		}
	}, [pageLogin]);

	const logIn = (formData) => {
		if (!user.userId) {
			if (formData) {
				if ('emailAddress' in formData) {
					_.extend(mockUser, _.pick(formData, _.keys(mockUser)));
					mockUser['userId'] = formData['emailAddress'];
				}
			}
			setUser(mockUser);
			setIsLoggedIn(true);
			localStorage.setItem("isLoggedIn", true);
			localStorage.setItem(userDetailKey, JSON.stringify(mockUser));
		}
	};

	const logOut = async (sendToLoginPage) => {
		setIsLoggedIn(false);
		localStorage.removeItem("isLoggedIn");
		localStorage.removeItem("userData");
		localStorage.removeItem("unlinkAccount");
		await submitLogout();
		localStorage.removeItem(userDetailKey);
		setUser({
			userId: '',
			userPermissionLevel: 1
		});
		const redirect = localStorage.getItem('logoutURL');
		location.assign(sendToLoginPage ? loginPageURL() : (redirect ? redirect : location.origin));
	};

	const deleteAccount = async (submitUrl) => {
		const response = await submitUserDelete(submitUrl);
		if (response["status"] !== undefined && (response["status"] == "success" || response["status"] == "sucess")) {
			logOut()
			return response
		} else {
			return false
		}
	};


	const uploadAvatar = () => {
	};

	const registerUser = (formData) => {

		let newUser = user;
		_.extend(newUser, _.pick(formData, _.keys(newUser)));
		if (formData) {
			if ('emailAddress' in formData) {
				newUser['userId'] = formData['emailAddress'];
			}
		}
		setUser(newUser);
		setIsLoggedIn(true);
	};

	const updateProfile = (formData) => {
		let newUser = user;
		_.extend(newUser, _.pick(formData, _.keys(newUser)));
		newUser['userId'] = formData['emailAddress'];
		setUser(newUser);
		localStorage.setItem(userDetailKey, JSON.stringify(newUser));
	};

	return (
		<UserContext.Provider
			value={{user, isLoggedIn, logIn, logOut, uploadAvatar, registerUser, updateProfile, deleteAccount}}>
			{children}
		</UserContext.Provider>
	);
};

UserProvider.propTypes = {
	children: PropTypes.any,
	pageLogin: PropTypes.bool
};
export default UserProvider;
