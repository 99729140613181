import React from 'react';
import { Cell, Grid, Row } from '../Grid';
import './ILImageGrid.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import nanoid from 'nanoid';
import PropTypes from 'prop-types';
import InstagramFilled from '../Icons/InstagramFilled';
import Link from '../Link';

const PartnersGrid = ({ heading, partners }) => {
	return <section className='c-il-grid'>
		<Grid noVerticalPadding>
			<Row>
				<Cell columns={12}>
					<h3 className='c-il-grid__heading'>{heading}</h3>
				</Cell>
			</Row>
			<Row>
				{partners && partners.map(({ partners }) => (partners && partners[0] &&
					<Cell smCols={1} mdCols={2} key={nanoid()}>
						<Img
							src={get(partners[0].logo, 'file.url')}
							className='c-il-grid__image'
							imgAlt={get(partners[0].logo, 'title')}
						/>
					</Cell>))}
			</Row>
		</Grid>
	</section>;
};
const SocialFeed = ({ heading, images }) => {
	return <section className='c-il-socialFeed'>
		<div className='c-il-socialFeed__inner'>
			<h3 className='c-il-socialFeed__heading'>{heading}</h3>
			<ul className='c-il-socialFeed__masonry'>
				{images && images.map(({ image, link }) => (
					<li key={nanoid()} className='c-il-socialFeed__masonry-item'>
						<Link to={link}
							  target='_blank'
							  rel='noopener noreferrer'>
							<Img
								src={get(image, 'file.url')}
								imgAlt={get(image, 'title')}

							/>
						</Link>
						<InstagramFilled className='c-il-socialFeed__icon' />
					</li>))}
			</ul>
		</div>
	</section>;
};

const ILImageGrid = ({ data }) => {
	const { heading, partners, displayType, socialFeedImages } = data;
	return displayType === 'socialFeed' ? <SocialFeed heading={heading} images={socialFeedImages} /> :
		<PartnersGrid heading={heading} partners={partners} />;
};


ILImageGrid.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		partners: PropTypes.array,
		displayType: PropTypes.string,
		socialFeedImages: PropTypes.array
	})
};
export default ILImageGrid;
