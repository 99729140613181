import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CookieBanner from '..';
import { event } from '../../../lib/analytics';

class CookieBannerContainer extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(optionSelected, action, id, category) {
		event({
			'eventName': 'Cookie Banner',
			'category': category || optionSelected,
			'action': action,
			'label': optionSelected,
			'Label_ID': id
		});
	}

	render() {
		return (
			<CookieBanner
				{...this.props}
				action={this.handleClick}
			/>
		);
	}
}

CookieBannerContainer.propTypes = {
	title: PropTypes.string,
};

export default CookieBannerContainer;
