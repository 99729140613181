import React from 'react';
import './ILTeamMatchupHeading.scss';
import { Cell, Grid, Row } from '../../Grid';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Link from '../../Link';
import IconDoubleArrowsLeft from '../../Icons/DoubleArrowsLeft';
import SelectDropdown from '../../SelectDropDown';

const ILTeamMatchupHeading = ({ contentfulData, season, seasonsOptions, onSeasonChange }) => {
	if (!contentfulData) return null;
	const { headingTextFilled, headingTextStroked, backLink, backLinkText } = contentfulData;
	return <div>
		<Grid noHorizontalPadding noVerticalPadding>
			<Row>
				<Cell mdCols={4} lgCols={9} xlCols={9} align='middle'>

					{
						backLinkText && backLink &&
						<Link className='c-il-team-matchup__back' to={backLink}>
							<IconDoubleArrowsLeft />
							<span className='c-il-team-matchup__back-text'>{backLinkText}</span>
						</Link>
					}

					<div className='c-il-team-matchup__hero-heading-container'>
						<h1 className='c-il-team-matchup__hero-heading'>
							<span className='c-il-team-matchup__hero-heading--stroke'>{headingTextStroked}</span>&nbsp;
							<span className='c-il-team-matchup__hero-heading--filled'>{headingTextFilled}</span>
						</h1>
						<SelectDropdown
							defaultValue={season}
							options={seasonsOptions}
							handleChange={onSeasonChange}
							isSearchable={false}
							className='c-il-dropdown c-il-dropdown--italic'
						/>
					</div>

				</Cell>
				<Cell mdCols={2} lgCols={3} xlCols={3} align='middle' className='c-il-team-matchup__hero-image'>
					<Img
						src={get(contentfulData.headingImage, 'file.url')}
						imgAlt={get(contentfulData.headingImage, 'title')}
					/>
				</Cell>
			</Row>
		</Grid>
	</div>;


};


ILTeamMatchupHeading.propTypes = {
	contentfulData: PropTypes.object,
	headingTextFilled: PropTypes.string,
	headingTextStoked: PropTypes.string,
	headingImage: PropTypes.object,
	backLink: PropTypes.string,
	seasonsOptions: PropTypes.array,
	backLinkText: PropTypes.string,
	season: PropTypes.object
};
export default ILTeamMatchupHeading;
