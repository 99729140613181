import React, { Fragment } from 'react';
import createIconComponent from './createIconComponent';

const IconSunny = createIconComponent({
	content: (
		<Fragment>
			<g>
				<path
					d='M32 0.764038C32.8284 0.764038 33.5 1.43561 33.5 2.26404V12.264C33.5 13.0925 32.8284 13.764 32 13.764C31.1716 13.764 30.5 13.0925 30.5 12.264V2.26404C30.5 1.43561 31.1716 0.764038 32 0.764038Z' />
				<path
					d='M4.72049 16.514C5.13471 15.7966 6.05209 15.5508 6.76953 15.965L15.4298 20.965C16.1472 21.3792 16.393 22.2966 15.9788 23.014C15.5646 23.7315 14.6472 23.9773 13.9298 23.5631L5.26953 18.5631C4.55209 18.1489 4.30628 17.2315 4.72049 16.514Z' />
				<path
					d='M18.299 5.53326C17.8848 4.81583 16.9674 4.57001 16.25 4.98423C15.5326 5.39844 15.2867 6.31583 15.701 7.03326L20.701 15.6935C21.1152 16.411 22.0326 16.6568 22.75 16.2426C23.4674 15.8283 23.7133 14.911 23.299 14.1935L18.299 5.53326Z' />
				<path
					d='M63.5 32.264C63.5 33.0925 62.8284 33.764 62 33.764H52C51.1716 33.764 50.5 33.0925 50.5 32.264C50.5 31.4356 51.1716 30.764 52 30.764H62C62.8284 30.764 63.5 31.4356 63.5 32.264Z' />
				<path
					d='M48.299 7.03326C48.7133 6.31583 48.4674 5.39844 47.75 4.98423C47.0326 4.57001 46.1152 4.81583 45.701 5.53326L40.701 14.1935C40.2867 14.911 40.5326 15.8283 41.25 16.2426C41.9674 16.6568 42.8848 16.411 43.299 15.6935L48.299 7.03326Z' />
				<path
					d='M59.2795 16.514C59.6937 17.2315 59.4479 18.1489 58.7305 18.5631L50.0702 23.5631C49.3528 23.9773 48.4354 23.7315 48.0212 23.014C47.607 22.2966 47.8528 21.3792 48.5702 20.965L57.2305 15.965C57.9479 15.5508 58.8653 15.7966 59.2795 16.514Z' />
				<path
					d='M33.5 52.264C33.5 51.4356 32.8284 50.764 32 50.764C31.1716 50.764 30.5 51.4356 30.5 52.264V62.264C30.5 63.0925 31.1716 63.764 32 63.764C32.8284 63.764 33.5 63.0925 33.5 62.264V52.264Z' />
				<path
					d='M48.0213 41.514C48.4355 40.7966 49.3529 40.5508 50.0703 40.965L58.7306 45.965C59.448 46.3792 59.6938 47.2966 59.2796 48.014C58.8654 48.7315 57.948 48.9773 57.2306 48.5631L48.5703 43.5631C47.8529 43.1489 47.6071 42.2315 48.0213 41.514Z' />
				<path
					d='M43.299 48.8345C42.8848 48.1171 41.9674 47.8713 41.25 48.2855C40.5326 48.6997 40.2867 49.6171 40.701 50.3345L45.701 58.9948C46.1152 59.7122 47.0326 59.958 47.75 59.5438C48.4674 59.1296 48.7133 58.2122 48.299 57.4948L43.299 48.8345Z' />
				<path
					d='M13.5 32.264C13.5 33.0925 12.8284 33.764 12 33.764H2C1.17157 33.764 0.5 33.0925 0.5 32.264C0.5 31.4356 1.17157 30.764 2 30.764H12C12.8284 30.764 13.5 31.4356 13.5 32.264Z' />
				<path
					d='M23.299 50.3345C23.7133 49.6171 23.4674 48.6997 22.75 48.2855C22.0326 47.8713 21.1152 48.1171 20.701 48.8345L15.701 57.4948C15.2867 58.2122 15.5326 59.1296 16.25 59.5438C16.9674 59.958 17.8848 59.7122 18.299 58.9948L23.299 50.3345Z' />
				<path
					d='M15.9787 41.514C16.3929 42.2315 16.1471 43.1489 15.4297 43.5631L6.76943 48.5631C6.05199 48.9773 5.13461 48.7315 4.7204 48.014C4.30618 47.2966 4.55199 46.3792 5.26943 45.965L13.9297 40.965C14.6471 40.5508 15.5645 40.7966 15.9787 41.514Z' />
				<path fillRule='evenodd' clipRule='evenodd'
					  d='M48 32.264C48 41.1006 40.8366 48.264 32 48.264C23.1634 48.264 16 41.1006 16 32.264C16 23.4275 23.1634 16.264 32 16.264C40.8366 16.264 48 23.4275 48 32.264ZM45 32.264C45 39.4437 39.1797 45.264 32 45.264C24.8203 45.264 19 39.4437 19 32.264C19 25.0843 24.8203 19.264 32 19.264C39.1797 19.264 45 25.0843 45 32.264Z' />

			</g>

		</Fragment>
	),
	height: 64,
	width: 64
});

IconSunny.displayName = 'IconSunny';

export default IconSunny;
