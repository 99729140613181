import React,{useState, useContext} from 'react';
import './TeamsSelection.scss';
import { TeamSelectionBlock } from './TeamSelectionBlock';
import { toast } from 'react-toastify';
import { submitDockForm} from '../../utils/submitDockForm';
import DockCTA from '../DockCTA/DockCTA';
import UserContext from '../../context/UserContext';


export default function TeamsSelection({data}) {
	const { heading, headingText, teams, successUrl, submitUrl, footnote } = data;
	const [teamSelected, setTeamSelect] = React.useState(null);
	const { logOut, updateProfile, user } = useContext(UserContext);

	const moveNext = async () => {
        if(teamSelected == null){
            toast.error( "You have not selected any team", {
				autoClose: false,
                position: toast.POSITION.TOP_CENTER
              });
        }else{
            toast.dismiss();
            const toastId = toast.loading("Please wait...")
              let submitData = {
                "favouriteTeam": teamSelected,
              };
              const response = await  submitDockForm(submitData,submitUrl);
              toast.dismiss();
              if(response["status"] !== undefined && response["status"] == "success"){
				  updateProfile({...user, ...submitData})
				location.assign(successUrl);
              }
			  else if(response["status"] !== undefined && response["status"] == "error" && response["redirect"] == true){
				toast.error( "Login expired", {
					autoClose: false,
				  position: toast.POSITION.TOP_CENTER
				});
				logOut();

			  }
			  else if(response["status"] !== undefined && response["status"] == "error"){
                  toast.error( "There is an error", {
					autoClose: false,
                  position: toast.POSITION.TOP_CENTER
                });
              }
        }
    };

	return (
		<section className="c-team-selection">
			<div className="c-team-selection__text-wrapper">
				<h1 className="c-team-selection__heading">
					{ heading && <span>{heading}</span> }
				</h1>
				<h4 className="c-team-selection__label">{ headingText }</h4>
				<h1> &nbsp;</h1>
			</div>

			<center>
				<div className="c-team-selection__teams-container">
					{
						teams.map(team => (
							<div
								className= "c-team-selection__team-block-wrapper"
								key={team.contentfulId}
								onClick={()=>{
									          setTeamSelect(team.code);
								        }}
							>
								<TeamSelectionBlock
									code={team.code}
									name={team.name}
									fullName={team.fullName}
									logo={team.logo}
									image={team.leaderboardFlag}
									angleFillColor = "#FFF"
									shadow={true}
									isNav={true}
									setTeamSelect = {setTeamSelect}
									teamSelected={teamSelected}
								/>

							</div>
						))
					}
				</div>
				<DockCTA
					onClick={moveNext}
					label={'Continue'}
				/>
				<p className="c-team-selection__footnote">{footnote}</p>
			</center>
		</section>
	);
};

