import React from 'react';
import { Cell, Grid, Row } from '../Grid';
import './ScoreCriteria.scss';
import { IconSun } from '../Icons';
import Underline from '../../../../public/images/Underline.png';
import { Img } from '@sailgp/sailgp-shared-components';

function ScoreCriteria({ data }) {
	return (
		<section className='il-score-section-main'>
			<Grid className='il-score-grid' noHorizontalPadding noVerticalPadding>
				<Row>
					<Cell className='il-score-criteria-right' xlCols={6} lgCols={6} smCols={2} mdCols={3}>
						<div className='il-score-card-head'>
							<h2 className='il-score-criteria-heading'>{data.heading ? data.heading : ''}</h2>
							<span className='il-score-criteria-heading-icon'>
								<IconSun />
							</span>
						</div>
						<p className='il-scoring-team-desc'>{data.description ? data.description : ''}</p>
					</Cell>
					{data.criteria.length > 1 ? (
						<Cell className='il-scoring-blocks' xlCols={6} lgCols={6} smCols={2} mdCols={3}>
							{data.criteria.map((criteria, index) => (
								<div key={index} className='il-scoring-block'>
									<div className='il-scoring-details'>
										<div className='il-block-wrap'>
											<h3 className='il-block-heading'>{criteria.title ? criteria.title : ''}</h3>
											<p className='il-block-desc'>{criteria.description ? criteria.description : ''}</p>
										</div>

										<div className='il-block-img'>
											<Img
												src={criteria.image.file.url}
												imgAlt={criteria.image.file.title}
											/>
										</div>
									</div>
								</div>
							))}
						</Cell>
					) : null}
				</Row>
			</Grid>
		</section>
	);
}

export default ScoreCriteria;
