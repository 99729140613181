import { toast } from 'react-toastify';
import { submitDockFormNoToken} from '../../../utils/submitDockForm';
import {dashBoardURL} from '../../../lib/content-api-utils';

export async function sendNotificationUserRegistration(paramData, submitData) {
    if(paramData!=undefined && submitData!=undefined){
        try{
            let userVerificationData = {
                    firstName : submitData.firstName,
                    redirectURL : location.origin + paramData.successUrl3,
                    emailTemplate: "UserVerification",
                    recipient : submitData.emailAddress,
                    tokenUrl : "user/generate-token",
                    userName : submitData.emailAddress,
                    operation: "VERIFY_USER"
            };
            let response = await submitDockFormNoToken(userVerificationData,paramData.emailNotificationUrl);
          }catch(e){
            toast.error( "Error", {
              autoClose: false,
              position: toast.POSITION.TOP_CENTER,
              });
          }
    }
}

export async function verifyUserEmail(paramData,queryParams) {
    if(paramData!=undefined && queryParams!=undefined){
      let params = new URLSearchParams(queryParams);
      let token = decodeURIComponent(params.get('token'));
      toast.loading("Verifying user email")
      if(token!=null && token.length != 0){
        let verifyUserData = {};
        verifyUserData['Token'] = token;
        try{
            let verifyUserResponse=await submitDockFormNoToken(verifyUserData,paramData.verifyUrl);
            if(verifyUserResponse!=undefined && verifyUserResponse.response.status!=undefined && verifyUserResponse.response.status == "success"){
             toast.success( verifyUserResponse.response.description, {
              position: toast.POSITION.TOP_CENTER, autoClose: 2000
              });
            }
            if(verifyUserResponse.response.status == "error"){
              toast.error( verifyUserResponse.response.status, {
              position: toast.POSITION.TOP_CENTER, 
              autoClose: false,
              });
            }
        }catch(e){
          // Error
        }
        location.assign(location.pathname);
      }
    }
}

export async function forgotPasswordEmail(paramData, submitData) {
    if(paramData!=undefined && submitData!=undefined){

      try{
          let userName = encodeURIComponent(submitData.emailAddress);
          let redirectUrl = location.origin + paramData.successUrl3;
          submitData["firstName"]=submitData.emailAddress;
          submitData["redirectURL"]=redirectUrl;
          submitData["emailTemplate"]="PasswordReset";
          submitData["recipient"]=submitData.emailAddress;
          submitData["tokenUrl"]="user/generate-token";
          submitData["userName"]=submitData.emailAddress;
          submitData["operation"]="FORGOT_PASSWORD";
      }catch(e){
        toast.error( "Error", {
          autoClose: false,
          position: toast.POSITION.TOP_CENTER
          });
      }
    }
}

export async function resetPassword(paramData,submitData,user) {
  if(paramData!=undefined && submitData!=undefined){
    let search = window.location.search;
    try {
      if(search.length != 0 && !localStorage.getItem("isLoggedIn")){
        let params = new URLSearchParams(search);
        let userName = decodeURIComponent(params.get('userName'));
        let token = decodeURIComponent(params.get('token'));
        submitData["UserName"]=userName;
        submitData["Token"]=token;
        submitData["Session"]=false;
      }
      else{
          submitData["UserName"] = user["userId"];
          submitData["Session"]=true;
          paramData["successUrl"]=dashBoardURL();
          paramData["submitUrl"]=paramData.successUrl2;
      }
    }catch(e){
        toast.error( "Error", {
          autoClose: false,
          position: toast.POSITION.TOP_CENTER
          });
    }
  }
}


export async function sendNotificationPasswordReset(paramData,submitData) {
    let sendNotificationData = {
      "Parameters": [
        {
          "Value": submitData.UserName,
          "Key": "userDisplayName"
        }],
      "EmailTemplate": "PasswordResetSuccess",
      "Recipient": submitData.UserName
    };
    try{
        await submitDockFormNoToken(sendNotificationData,paramData.emailNotificationUrl);
    }catch(e){
      // Error
    }
}

export default{
    sendNotificationUserRegistration,
    verifyUserEmail,
    forgotPasswordEmail,
    resetPassword,
    sendNotificationPasswordReset
}
