import fetch from 'isomorphic-fetch';
import { getContentBaseAPIURL } from './../../../lib/content-api-utils';
import Logger from './../../../lib/logger';

const logger = Logger.get('Leaderboard');
const baseURL = getContentBaseAPIURL();


export default async function fetchLeaderboardAPI(options = { season: 'SGP-IL-S03-CHAMP', event: 'season' }) {
	try {
		const { season, event } = options;
		const res = await fetch(`${baseURL}impact-league-api/getleaderboard/${season}/${event}`);
		const json = await res.json();

		return json;
	} catch (error) {
		logger.error(error);
		return new Error('Network response was not ok', error);
	}
}
