import React from 'react'

const UserContext = React.createContext({
	user : {},
	logOut: () => {},
	isLoggedIn: false,
	uploadAvatar: () => {},
	logIn: () => {},
	registerUser: () => {},
	updateProfile: () => {},
	deleteAccount: () => {},
})

export default UserContext;
