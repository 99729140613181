import React from 'react';
import PropTypes from 'prop-types';
import Category from './Category';

export default function ScoreCategoryContainer(props) {
	const { data } = props;
	return <Category data={data} />;
}

ScoreCategoryContainer.propTypes = {
	data: PropTypes.object
};

ScoreCategoryContainer.defaultProps = {
	data: {}
};
