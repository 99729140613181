import * as nearAPI from "near-api-js";
import {nearNodeURL, nearWalletURL, nearHelperURL, nearExplorerURL, getWalletName} from '../../lib/content-api-utils';
import SettingsContext from '../../context/SettingsContext';

export class MyWallet {
	walletSelector;
	wallet;
	network;
	createAccessKeyFor;
	nearConnection;
	walletConn;
	_successUrl;
	_failureUrl


	constructor({
					createAccessKeyFor = undefined,
					network = 'testnet',
					successUrl = undefined,
					failureUrl = undefined,
					nearEnvironmentKeys
				}) {

		this.createAccessKeyFor = createAccessKeyFor
		this.network = network
		this._successUrl = successUrl
		this._failureUrl = failureUrl
		this.nearEnvironmentKeys = nearEnvironmentKeys
	}


	// To be called when the website loads
	async startUp() {
		const {connect, keyStores, WalletConnection} = nearAPI;
		const connectionConfig = {
			networkId: this.network,
			keyStore: new keyStores.BrowserLocalStorageKeyStore(),
			nodeUrl: this.nearEnvironmentKeys.NEAR_NODE_URL,
			walletUrl: this.nearEnvironmentKeys.NEAR_WALLET_URL,
			helperUrl: this.nearEnvironmentKeys.NEAR_HELPER_URL,
			explorerUrl: this.nearEnvironmentKeys.NEAR_EXPLORER_URL,
		};

		this.nearConnection = await connect(connectionConfig);
		this.walletConn = new WalletConnection(this.nearConnection, getWalletName());
	}

	async signIn() {
		this.walletConn.requestSignIn({
			contractId: this.createAccessKeyFor,
			methodNames: [], // optional
			successUrl: location.origin + this._successUrl, // optional redirect URL on success
			failureUrl: location.origin + this._failureUrl
		});
	}

}
