import React, { memo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {withNavigation} from './index';
import {SideNavigationDrawer} from '@sailgp/sailgp-shared-components';
import {clearAllBodyScrollLocks} from 'body-scroll-lock';
import PageContext from '../../context/PageContext';
import UserContext from '../../context/UserContext';

const SideNavigationDrawerContainer = (
	{	data,
		navItems,
		selectedItem,
		clearSelectedItem,
		getSecondaryNavItems,
		getSelectedNavItem,
		revealSecondaryNav,
		onSelectItemHandler,
	}) => {

	useEffect(() => () => {
		clearAllBodyScrollLocks();
	}, []);

	const {isSideDrawerOpen, toggleSideDrawerOpen, isLanguageSelectOpen, closeLanguageSelect} = useContext(PageContext)
	const {isLoggedIn, user, logOut, uploadAvatar} = useContext(UserContext)

	return (
		<SideNavigationDrawer
			uploadPicture={false}
			revealSecondaryNav={revealSecondaryNav}
			backLabel={data.backLabel}
			closeLabel={data.closeLabel}
			isSideDrawerOpen={isSideDrawerOpen}
			toggleSideDrawerOpen={toggleSideDrawerOpen}
			navItems={navItems}
			selectedItem={selectedItem}
			clearSelectedItem={clearSelectedItem}
			getSecondaryNavItems={getSecondaryNavItems}
			isLanguageSelectOpen={isLanguageSelectOpen}
			closeLanguageSelect={closeLanguageSelect}
			onSelectItemHandler={onSelectItemHandler}
			accountLinks={data.accountLinks}
			user={{isLoggedIn, ...user}}
			logOut={logOut}
			requestNotifications={12}
			uploadAvatar={uploadAvatar}
		/>

	);
}

SideNavigationDrawerContainer.propTypes = {
	children: PropTypes.any,
	navItems: PropTypes.array,
	selectedItem: PropTypes.any,
	clearSelectedItem: PropTypes.func,
	getSecondaryNavItems: PropTypes.func,
	getSelectedNavItems: PropTypes.func,
	getSelectedNavItem: PropTypes.func,
	revealSecondaryNav: PropTypes.func,
	onSelectItemHandler: PropTypes.func,
	data: PropTypes.object
};

export default memo(withNavigation(SideNavigationDrawerContainer));
