import fetch from 'isomorphic-fetch';
import { getContentAPIURL } from './../lib/content-api-utils';
import trailingSlash from './../lib/trailing-slash-it';

const getInitialData = async ({ match, options }) => {
	try {
		let page = 'root';
		let url = getContentAPIURL();

		if (match.url !== '/' && match.url !== '/index.html') {
			page = match.params.page;

			// The trailing slash `/` is important. If we missed it the API might return 404
			url += `page/${trailingSlash(page)}`;
		} else {
			url += `page/${page}`;
		}

		let headers = {};
		if (options && options.headers) {
			headers = { ...options.headers };
		}

		console.log(url)
		const response = await fetch(url, {
			headers: new Headers(headers),
		});

		if (response.ok === false) {
			let errObj;

			switch (response.status) {
				case 404:
					errObj = {
						errorType: 404,
						errorMessage: `404 Content API: Page '${page}' was not found`,
						error: response
					}
					break;

				default:
					errObj = {
						errorType: response.status,
						errorMessage: response.statusText,
						error: response
					}
					break;
			}

			return errObj;
		}

		const data = await response.json();

		return data;
	} catch (error) {
		console.log(error); // eslint-disable-line no-console

		return {
			errorType: 500,
			error: error
		};
	}
};

export default getInitialData;
