import React, {Component} from 'react';
import './RolexWatch.scss';
import PropTypes from 'prop-types';
import {setRolexWatchTime, createRolexWatch} from './scripts/rolex-core-1.4.2'

class RolexWatch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: new Date(this.props.time),
		};
		this.clockRef = React.createRef();
	}

	componentDidMount() {
		const dimensions = {width: 180, height: 180, scale: 0.97};
		const settings = {
			clockWidth: dimensions.width,
			clockHeight: dimensions.height,
			globalXcenter: (dimensions.width / 2) - 0.75,
			globalYcenter: (dimensions.height / 2) + 2.5,
			globalXScale: dimensions.scale,
			globalYScale: dimensions.scale,
			trackingUrl: 'https://www.rolex.com/?cmpid=dsp_Clock_sailgp.com_2019_rlx1900188',
			handsFolder: `${window.location.origin.toString()}/rolex/images/hands/white/`,
			watchContainerID: this.clockRef.current,
		};
		setRolexWatchTime(this.props.time);
		createRolexWatch(settings);
	}

	componentDidUpdate(_prevProps, _prevState, _snapshot) {
		// Prevents the watch from twitching when the component updates without a time change.
		if (this.props.time.valueOf() !== this.state.time.valueOf()) {
			this.setState({time: this.props.time})
			setRolexWatchTime(new Date(this.props.time));
		}
	}

	render() {
		return (
			<div>
				<div className="container">
					<a
						ref={this.clockRef}
						id="clock"
						target="_blank"
						rel="noopener noreferrer"
						aria-label='rolex clock'
						href="https://www.rolex.com/?cmpid=dsp_Clock_sailgp.com_2019_rlx1900188"
						className="clock clock-90"
					/></div>
			</div>
		)
	}
}

RolexWatch.propTypes = {
	time: PropTypes
		.instanceOf(Date)
		.isRequired
}

export default RolexWatch;
