import React from 'react';
import './ILLeaderboardPodium.scss';
import { Cell, Grid, Row } from '../../Grid';
import { Img } from '@sailgp/sailgp-shared-components';
import get from 'lodash/get';
import { getPartnerData, getPositionLabel, getTeam } from '../utils/helpers';
import PropTypes from 'prop-types';
import ILScrollPrompt from '../../ILScrollPrompt/ILScrollPrompt';

const ILLeaderboardPodium = ({ LeaderBoard, contentfulData }) => {
	if (!LeaderBoard || !contentfulData) return null;

	const topTeams = LeaderBoard && LeaderBoard.length > 0 ? LeaderBoard.slice(0, 3) : [];

	if (!topTeams || topTeams.length === 0) return null;

	return <section className='c-il-leaderboard__podium'>
		<Grid noHorizontalPadding noVerticalPadding>
			<Row>
				<Cell columns={12}>
					<div className='c-il-leaderboard-podium'>
						{topTeams.map((leaderboardTeam, index) => {
								const team = getTeam(contentfulData.listOfTeams, leaderboardTeam.TeamName);
								const teamPartner = getPartnerData(team);
								return <div className='c-il-leaderboard-podium__card' key={index}>
									<div className='c-il-leaderboard-podium__card-inner'>
										<div className='c-il-leaderboard-podium__card-header'>
											<label
												className='c-il-leaderboard-podium__card-rank'>{getPositionLabel(index)}</label>
											<label className='c-il-leaderboard-podium__card-team'>{team.name}</label>
										</div>
										<div className='c-il-leaderboard-podium__card-content'>
											<div className='c-il-leaderboard-podium__card-content-wrap'>
												<label
													className='c-il-leaderboard-podium__card-type'>{teamPartner.type}</label>
												<h4 className='c-il-leaderboard-podium__card-slogan'>{teamPartner.slogan}</h4>
												<p className='c-il-leaderboard-podium__card-message'>{teamPartner.message}</p>
											</div>
										</div>
										<div className='c-il-leaderboard-podium__card-flag'>
												<span>
														<Img
															src={get(team.leaderboardFlag, 'file.url')}
															imgAlt={get(team.leaderboardFlag, 'title')}
														/>
												</span>
										</div>
									</div>
								</div>;
							}
						)}
						<div className='u-hidden-md'>
							<ILScrollPrompt scrollPromptText='scroll for team standing' />
						</div>
					</div>
				</Cell>

			</Row>
		</Grid>
	</section>;
};

ILLeaderboardPodium.propTypes = {
	contentfulData: PropTypes.object,
	LeaderBoard: PropTypes.array
};

export default ILLeaderboardPodium;
