import React, { useContext, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import pageComponentsList from '../../../containers/ComponentsList';
import { Grid, Row, Cell } from '../../Grid';
import './FanzoneNFTList.scss';
import { submitDockFormNoBody } from '../../../utils/submitDockForm';
import UserContext from '../../../context/UserContext';
import NftItem from './NftItem';
import { AlternateComponents } from './alternateComponents';

const FanZoneNFTList = (props) => {

	const [nftList, setNFTList] = useState(false);
	const nearLogoLight = require('./images/logo_rev.png');
	const nearLogoDark = require('./images/logo.png');

	const { logOut } = useContext(UserContext);

	const initialDataUrl = props.data.submitUrl;
	const redirectionTargetURL = location.origin;
	const [moduleState, setModuleState] = useState(false);
	const initNFTList = async () => {
		const response = await submitDockFormNoBody(initialDataUrl);
		if (response.status !== undefined && (response.status == 'success' || response.status == 'sucess')) {
			setNFTList(response);
			if (response.linkType && response.linkType == 'Custodial') {
				setModuleState('custodial');
			} else if (response.linkType && response.linkType == 'Non-custodial') {
				setModuleState('noncustodial');
			} else {
				setModuleState('default');
				localStorage.removeItem('unlinkAccount');
			}

		} else if (response.status !== undefined && response.status == 'error' && response.redirect == true) {
			logOut();
		}
	};

	useEffect(() => {
		initNFTList();
	}, []);

	const fallbackItems = (
		<AlternateComponents
			validComponents={pageComponentsList.configurableGridComponents}
			data={props.data.alternateComponentContent}
		/>
	);

	function getNearName() {
		if (nftList) {
			return nftList.nearAccountId ? nftList.nearAccountId : '';
		}
		return '';
	}

	function getNearBalance() {
		if (nftList) {
			return nftList.nearBalance ? +nftList.nearBalance.toFixed(2) : '---';
		}
		return '---';
	}


	// Output for module is dependent on state. Currently will output static message if user
	// has no NFTs assigned but has connected a NEAR account.
	return (
		<>
			<Grid className={'remove-horizontal-padding ' + moduleState}>
				<Row className={'custom_row columns_not_sticky NEARHeader'} style={{
					'backgroundColor': 'lightgrey',
					'backgroundImage': 'url(\'' + props.data.desktopBg.file.url + '\')',
					'backgroundSize': 'cover',
				}}>
					<Cell smCols={2} mdCols={6} columns={6} className={'custom_grid'}>
						<div>
							<div>
								{moduleState == 'default' ?
									<img src={nearLogoDark} alt='NEAR' /> :
									<img src={nearLogoLight} alt='NEAR' />
								}
							</div>

							{moduleState === 'custodial' && <div className='subheader'>
								Hi {getNearName()}. To retain ownership of your Web3 digital collectibles after Season
								4, get your passphrase here!
							</div>

							}

							{moduleState === 'noncustodial' && <div className='subheader'>
								Hi {getNearName()}
							</div>

							}
						</div>
					</Cell>
					<Cell smCols={2} mdCols={6} columns={6} className={'custom_grid'}>
						<div>
							<div className='insetContainer'>
								<div className='insetBox'>
									{moduleState == 'noncustodial' || moduleState == 'custodial' ?
										<div><h2>{getNearBalance()}</h2><p>Available Balance</p></div> : ''
									}
								</div>
								{moduleState === 'custodial' && (localStorage.getItem('unlinkAccount') === undefined || localStorage.getItem('unlinkAccount') === null) ?
									<div><a href={redirectionTargetURL + '/dock/near-passphrase/'}
											className='c-button c-button--primary'
											aria-label='SailGP TAKE OWNERSHIP'><span className='c-button__label'>TAKE OWNERSHIP</span></a>
									</div> : ''
								}

								{moduleState === 'noncustodial' && (localStorage.getItem('unlinkAccount') === undefined || localStorage.getItem('unlinkAccount') === null) ?
									<div><a href={redirectionTargetURL + '/dock/near-unlink-account-non-custodial/'}
											className='c-button c-button--primary'
											aria-label='SailGP UnlinkAccount'><span className='c-button__label'>Unlink Account</span></a>
									</div> : ''
								}

							</div>
						</div>
					</Cell>
				</Row>
			</Grid>
			{nftList != null && nftList.nfts ?
				<Grid className='l-grid'>
					<h2 className='intermediary-header'>NFT Gallery</h2>
					<div className='collectible-item-grid-container'>
						{nftList.nfts.map(nftEle => <div className='collectible-item-itemOuterContainer'
														 key={nftEle.token_id}><NftItem {...nftEle} /></div>)}
					</div>
				</Grid>
				: ''}
			{moduleState == 'default' ? fallbackItems : ''}
			{moduleState != 'default' && (!nftList.nfts || nftList.nfts.length <= 0) ? <Grid className='l-grid'>
				<h2 className='intermediary-header'>NFT Gallery</h2>
				<div className='collectible-item-notification-container'>
					<div className='collectible-empty-notification'>You have no collectibles.</div>

				</div>
			</Grid> : ''}
		</>
	);
};

FanZoneNFTList.propTypes = {
	data: PropTypes.object,
};

FanZoneNFTList.defaultProps = {
	data: {},
};

export default FanZoneNFTList;
