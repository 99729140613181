import React, {useContext, useState, useEffect, memo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Button} from '@sailgp/sailgp-shared-components';
import {
	submitDockForm,
	submitDockFormNoBody,
	submitLogin,
	submitRegister,
} from '../../../utils/submitDockForm';
import UserContext from '../../../context/UserContext';
import DockCTA from '../../DockCTA/DockCTA';
import {fetchContent} from '../../../utils/fetchPageContent';
import SettingsContext from '../../../context/SettingsContext';
import DynamicControl from './DynamicControl';
import InFormParagraph from './InFormParagraph';
import EditControl from './EditControl';
import {
	sendNotificationUserRegistration,
	verifyUserEmail,
	forgotPasswordEmail,
	resetPassword,
	sendNotificationPasswordReset
} from './SendNotification';

import {getAppName} from '../../../lib/content-api-utils';
import {format} from 'date-fns';

const WebForm = ({formData, serverSettings}) => {

	const paramData = formData;
	const formMethods = useForm({mode: "onBlur"});
	let formFields = [];
	if(paramData.hasOwnProperty('formFields')) {
		formFields = paramData.formFields;
	}

	const {logIn, logOut, updateProfile, user, deleteAccount} = useContext(UserContext)
	const {pageSettings} = useContext(SettingsContext)
	const {nearEnvironmentKeys} = pageSettings

	const {
		handleSubmit,
		formState: {isSubmitting, errors}
	} = formMethods;

	const [userData, setUserData] = useState({});
	const [seedWordPosition, setseedWordPosition] = useState(0);
	const [countryList, setCountryList] = useState(false);
	const [teamList, setTeamList] = useState(false);

	if (paramData.formSubmitAction == "updateProfile" || paramData.formSubmitAction == "registerUser") {
		useEffect(() => {
			(async () => {
				let countryListResult = await fetchContent('country');
				setCountryList(countryListResult);
				let teamListResult = await fetchContent('teams?incGeneral=true&onlyActive=true');
				setTeamList(teamListResult);
			})()

		}, [])
	}

	if (paramData.formSubmitAction == "updateProfile") {
		useEffect(() => {
			(async () => {
				let response = await submitDockFormNoBody(paramData.initialDataUrl);

				if (response["status"] !== undefined && (response["status"] == "success" || response["status"] == "sucess")) {
					setUserData(response["response"]);
				} else {
					logOut();
				}
			})()

		}, [])
	}
	//Verify User Email :Start
	if (paramData.formSubmitAction == "logIn" && paramData.verifyUrl != null) {
		useEffect(() => {
			(async () => {
				let search = window.location.search;
				let urlParams = new URLSearchParams(search);
				let pageName = decodeURIComponent(urlParams.get('pageName'));
				let mobileToken = decodeURIComponent(urlParams.get('mobileToken'));
				if (search.length !== 0 && pageName === 'null' && mobileToken === 'null') {
					verifyUserEmail(paramData, search)
				}
			})()
		}, [])
	}
	//Verify User Email :End

	if (paramData.formSubmitAction == "verifyPassphrase") {

		useEffect(() => {
			let randNum = Math.floor(Math.random() * 12) + 1;
			setseedWordPosition(randNum)
		}, []);
	}

	const onSubmit = async (submitData, errors) => {
		const toastId = toast.loading("Please wait...");

		//Forgot password notification :Start
		if (paramData.formSubmitAction == "forgotPassword") {
			forgotPasswordEmail(paramData, submitData);
		}
		//Forgot password notification :End
		//Reset new password :Start
		if (paramData.formSubmitAction == "resetPassword") {
			resetPassword(paramData, submitData, user);
		}
		//Reset new password :End

		if (paramData.submitUrl == undefined || paramData.submitUrl == "") {
			toast.dismiss();
			toast.error("There is an error on this page !", {
				autoClose: false,
				position: toast.POSITION.TOP_CENTER
			});
		} else {
			if (paramData.submitUrl != undefined && paramData.submitUrl != "") {

				// Adding the field which is not there in the screen
				let response;
				if (paramData.formSubmitAction == "registerUser") {

					const formattedDateOfBirth = format(submitData.dateOfBirth, 'yyyy-MM-dd')
					submitData.dateOfBirth = formattedDateOfBirth;
					delete submitData['date-input'];

					delete submitData.repeatPassword;
					submitData.newsletterConsent = submitData.gdprConsent;
					submitData.applicationName = getAppName();
					const params = new URLSearchParams(location.search);

					if (params && params.get('_cts_')) {
						submitData.inviteCode = params.get('_cts_');
					}
					response = await submitRegister(submitData, paramData.submitUrl);
				} else if (paramData.formSubmitAction == "updateProfile") {
					submitData.applicationName = getAppName();
					submitData.newsletterConsent = submitData.gdprConsent;
					response = await submitRegister(submitData, paramData.submitUrl);
				} else if (paramData.formSubmitAction == "logIn") {
					// populate redirection URLs for user after login completes.
					submitData.successUrl = location.origin + paramData.successUrl;
					submitData["successUrl2"] = location.origin + paramData.successUrl2;
					submitData.applicationName = getAppName();
					const cookieObj = {
						httpOnly: true,
						secure: true,
						sameSite: 'None',
						domain:'.dev.sailgp.com',
						maxAge: 20 * 60 * 1000 // Set to 20- minutes
					}
					let localCookieData = localStorage.getItem('cookieObj');
					if(localCookieData == null){
						localStorage.setItem('cookieObj', JSON.stringify(cookieObj));
						localCookieData = localStorage.getItem('cookieObj');
					}
					submitData["localStorageData"] = localCookieData;

					response = await submitLogin(submitData, paramData.submitUrl);
				} else if (paramData.formSubmitAction === "deleteSailgpAccount") {
					// populate redirection URLs for user after delete completes.
					response = await deleteAccount(paramData.submitUrl);
				} else {
					if (paramData.formSubmitAction == "verifyPassphrase") {
						submitData.seedWordPosition = seedWordPosition;
						localStorage.setItem("unlinkAccount",true);
					}
					response = await submitDockForm(submitData, paramData.submitUrl);
				}
				toast.dismiss();
				if (response && (response["status"] !== undefined && response["status"] == "success")) {

					let redirectionTargetURL = location.origin + paramData.successUrl;
					if (paramData.formSubmitAction == "logIn") {
						logIn(response.userDetails);
						const storedURL = sessionStorage.getItem('logout-url');
						redirectionTargetURL = response.redirectURL;
						let logoutTargetURL = response.logoutRedirectURL;
						localStorage.setItem('logoutURL', logoutTargetURL ? logoutTargetURL : redirectionTargetURL);
						if (storedURL) {
							sessionStorage.removeItem('logout-url');
							redirectionTargetURL = redirectionTargetURL.split('?')[0] + '?r=' + storedURL;
						}
					}
					//Send Email notification on successful user registration : Start
					if (paramData.formSubmitAction == "registerUser") {
						sendNotificationUserRegistration(paramData, submitData);
					}
					//Send Email notification on successful user registration :End

					//Send Email notification on successful password reset : Start
					if (paramData.formSubmitAction == "resetPassword" && response.response != undefined
						&& response.response.status != undefined) {
						if (response.response.status == "success") {
							sendNotificationPasswordReset(paramData, submitData);
						} else {
							toast.error('Please enter a valid password:    ' + '\n' +
								' -The password must have at least 8 characters.' + '\n' +
								' -The password cannot exceed 40 characters.' + '\n' +
								' -The password must have at least 1 lowercase characters.' + '\n' +
								' -The password must have at least 1 numeric characters.' + '\n' +
								' -The password must have at least 1 special characters.' + '\n' +
								' -The password must have at least 1 uppercase characters.' + '\n' +
								' -The password cannot contain the First and Last Name of the user.' + '\n' +
								' -Cannot repeat last 4 passwords.', {
								autoClose: false,
								position: toast.POSITION.TOP_CENTER
							});
						}
					}
					//Send Email notification on successful password reset : End
					//TODO : this will not update to the latest chagne. need to pull latest user data and assign this.
					if (paramData.formSubmitAction == "updateProfile") {
						updateProfile(submitData)
					}
					if (paramData.formSubmitAction == "registerUser" || paramData.formSubmitAction == "resetPassword") {
						if ((paramData.formSubmitAction == "registerUser" && response["status"] == "success")
							|| (paramData.formSubmitAction == "resetPassword" && response.response.status == "success")) {
							window.setTimeout(function () {
								location.assign(redirectionTargetURL);
							}, 2000);
						}
					} else {
						location.assign(redirectionTargetURL);
					}
				} else if (response && response['status'] !== undefined && response['status'] == 'error' && response['errorDescription'] != undefined) {
					toast.error(response['errorDescription'], {
						autoClose: false,
						position: toast.POSITION.TOP_CENTER
					});
				} else if (response && response['status'] !== undefined && response['status'] == 'failed' && response['error_description'] != undefined) {
					toast.error(response['error_description'], {
						autoClose: false,
						position: toast.POSITION.TOP_CENTER
					});
				} else {
					toast.error('Server Error !', {
						autoClose: false,
						position: toast.POSITION.TOP_CENTER
					});
				}
			} else {
				toast.dismiss();
				toast.error('Server Error !', {
					autoClose: false,
					position: toast.POSITION.TOP_CENTER
				});
			}
		}
	};

	const getControlComponent = (field) => {

		switch (paramData.formSubmitAction) {
			case 'updateProfile':
				return <EditControl fieldData={field} fieldValue={userData[field.apiField]}
									userData={userData} setUserData={setUserData} countryData={countryList}
									teamData={teamList}/>;
			default:
				return <DynamicControl fieldData={field} countryData={countryList}/>;
		}
	};

	const isAppView = serverSettings && serverSettings.contentOnly

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)} style={{height: "auto"}}
				  className={`${paramData.formSubmitAction}${isAppView ? '__app' : ''}`}>
				<FormProvider {...formMethods} >
					<div className="flex flex-col md:flex-row gap-7 w-full py-7 px-4 shadow-md rounded-sm">
						{formFields && formFields.map((field) => (field.type === "paragraph")
							? (
								<InFormParagraph fieldData={field} key={field.contentfulId} isAppView={isAppView}/>
							)
							: (
								<div className={'inputField flex flex-col '
									+ (field.required ? 'required ' : '')
									+ (field.extraMarginBottom ? 'mb-large ' : '')
									+ (field.extraMarginTop ? 'mt-large ' : '')
									+ (field.appExtraMarginTop ? 'app-mt-large ' : '')
									+ (field.appExtraMarginBottom ? 'app-mb-large ' : '')
									+ (field.appExtraHugeMarginBottom ? 'app-mb-huge ' : '')
									+ (errors[field.apiField] ? 'error ' : '')
									+ (field.appClassNames
										&& field.appClassNames.length > 0
										&& field.appClassNames.join(' '))}
									 data-field-type={field.type}
									 key={field.contentfulId}>
									{
										field.label &&
										<div>
											<label htmlFor={field.apiName}
												   className="text-sm">{field.label}{seedWordPosition > 0 && seedWordPosition}</label>
										</div>
									}
									{
										field.label && field.miniContent &&
										<div>
											<p className="text-mini">{field.miniContent}</p>
										</div>

									}
									<div>
										{getControlComponent(field)}

										{errors[field.apiField] && errors[field.apiField].type === "required" && (
											<p className="text-red-500">{field.errorMessage}</p>
										)}
										{errors[field.apiField] && (errors[field.apiField].type === "pattern"
											|| errors[field.apiField].type === "validate") && (
											<p className="text-red-500">{field.validationErrorMessage}</p>
										)}
									</div>


								</div>
							))}
						{isAppView ?
							<Button
								className={isAppView && (isSubmitting || (Object.keys(errors).length > 0)) ? 'c-button__app-disabled' : isAppView ? 'c-button__app' : ''}
								type="submit" disabled={isSubmitting || (Object.keys(errors).length > 0)}>
								{paramData.submitLabel || (isSubmitting ? 'Submitting' : 'Submit')}
							</Button>
							:
							<DockCTA
								type="submit" disabled={isSubmitting || (Object.keys(errors).length > 0)}
								label={paramData.submitLabel || (isSubmitting ? 'Submitting' : 'Submit')}
							/>
						}

					</div>
				</FormProvider>

			</form>
		</div>

	)
};

export default memo(WebForm);
