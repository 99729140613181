/* global process */


/**
 * Get the content API url based on the running context, server or browser.
 *
 * @returns {String} - Content API url
 */
export function getContentAPIURL() {
	return process.env.BUILD_TARGET === 'client' ? process.env.CONTENT_API_URL_CLIENT : process.env.CONTENT_API_URL_SERVER;
}

export function getContentBaseAPIURL() {
	return process.env.BUILD_TARGET === 'client' ? process.env.CONTENT_BASE_API_URL_CLIENT : process.env.CONTENT_BASE_API_URL_SERVER;
}

export function getDockAPIURL() {
	return process.env.BUILD_TARGET === 'client' ? (process.env.CONTENT_BASE_API_URL_CLIENT+process.env.CONTENT_DOCK_CONTEXT) : (process.env.CONTENT_BASE_API_URL_SERVER + process.env.CONTENT_DOCK_CONTEXT);
}

export function loginPageURL() {
	return process.env.LOGIN_URL;
}

export function registerURL() {
	return process.env.REGISTER_URL;
}

export function dashBoardURL() {
	return process.env.DASHBOARD_URL;
}

export function dockLandingURL() {
	return process.env.DOCK_LANDING_PAGE;
}

export function dockChangePasswordURL() {
	return process.env.DOCK_CHANGE_PASSWORD_PAGE;
}

export function getWalletName(){
	return process.env.WALLET_NAME;
}

export function getErrorPage(){
	return process.env.DOCK_ERROR_PAGE;
}

export function getAppName(){
	return process.env.APP_NAME_SAILGP;
}
