import React, { useRef, useState } from 'react';
import {
	Accordion as AccordionWrapper,
	AccordionItem,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState
} from 'react-accessible-accordion';
import './ILLeaderboardAccordion.scss';
import { Img } from '@sailgp/sailgp-shared-components';
import IconPlus from '../../Icons/Plus';
import IconMinus from '../../Icons/Minus';
import ILLeaderboardCategories from '../ILLeaderboardCategories/ILLeaderboardCategories';
import { ScoreBreakdown } from '../Tile';
import { getPartnerData, getTeam } from '../utils/helpers';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import nanoid from 'nanoid';
import { useQuery } from '@tanstack/react-query';

const LeaderboardHead = () => {
	return <div className='c-il-leaderboard__header'>
		<div className='c-il-leaderboard__header-empty'></div>
		<div className='c-il-leaderboard__header-content'>
					<span className='c-il-leaderboard__header-position'>
						<span className='c-il-leaderboard__show--desktop'>Position</span>
						<span className='c-il-leaderboard__show--mobile'>Pos</span>
					</span>
			<span className='c-il-leaderboard__header-flag c-il-leaderboard__show--desktop'></span>
			<span className='c-il-leaderboard__header-name'>Team</span>
			<span className='c-il-leaderboard__header-partner c-il-leaderboard__show--desktop'>Partner</span>
			<span className='c-il-leaderboard__header-points'>
						<span className='c-il-leaderboard__show--desktop'>Impact points</span>
						<span className='c-il-leaderboard__show--mobile'>Points</span>
					</span>
		</div>
		<div className='c-il-leaderboard__header-button'></div>
	</div>;
};

const LeaderboardRow = (props) => {
	const [panelHeight, setPanelHeight] = useState(0);
	const panelRef = useRef(null);


	const onAccordionStateUpdate = ({ expanded }) => {
		if (panelRef.current) {
			setPanelHeight(expanded ? panelRef.current.scrollHeight : 0);
		}
		return <>
			<span className='c-il-leaderboard__row-button__text'>See
				{expanded ? ' Less' : ' More'}
			</span>
			<span className='c-il-leaderboard__row-button__icon'>
				{expanded ? <IconMinus /> : <IconPlus />}
			</span>
		</>;
	};

	const { Position, TeamName, PointsAwarded, CategoryScores, CriteriaBreakdown, contentfulData } = props;

	const team = getTeam(contentfulData.listOfTeams, TeamName);
	let teamPartner;
	if(team) {
		teamPartner = getPartnerData(team);
	}


	return (<>
			{team && teamPartner ? <AccordionItem>
				<div className='c-il-leaderboard__row'>
					<div className='c-il-leaderboard__row-empty'></div>
					<div className='c-il-leaderboard__row-content'>
						<span className='c-il-leaderboard__row-position'>{Position}</span>
						<span className='c-il-leaderboard__row-flag c-il-leaderboard__show--desktop'>
					<Img
								src={get(team.leaderboardFlag, 'file.url')}
								imgAlt={get(team.leaderboardFlag, 'title')}
							/>
				</span>
						<h3 className='c-il-leaderboard__row-name'>{team.name}</h3>
						<span className='c-il-leaderboard__row-partner c-il-leaderboard__show--desktop'>
					<Img
								src={get(teamPartner.logo, 'file.url')}
								imgAlt={get(teamPartner.logo, 'title')}
							/>
				</span>
						<span className='c-il-leaderboard__row-points'> {PointsAwarded}
							<span className='c-il-leaderboard__row-metric'>pts</span>
				</span>
					</div>
					<AccordionItemButton className='c-il-leaderboard__row-button'>
						<AccordionItemState>
							{onAccordionStateUpdate}
						</AccordionItemState>
					</AccordionItemButton>
				</div>

				<AccordionItemPanel className='c-il-leaderboard__panel'>
					<div
						ref={panelRef}
						className='c-il-leaderboard__panel-content-wrapper'
						style={{
							height: panelHeight
						}}
					>
						<div className='c-il-leaderboard__panel-content'>
							<div className='c-il-leaderboard__panel-partner c-il-leaderboard__show--mobile'>
								<Img
									src={get(teamPartner.logo, 'file.url')}
									imgAlt={get(teamPartner.logo, 'title')}
								/>
							</div>
							{CategoryScores &&
								<ILLeaderboardCategories
									updateAcordionHeight={setPanelHeight}
									categories={CategoryScores}
									boat={team.boatSchematicImage}
								/>
							}
							{CriteriaBreakdown &&
								<div
									className='c-il-leaderboard__categories'>
									<Img
										src={get(team.boatSchematicImage, 'file.url')}
										imgAlt={get(team.boatSchematicImage, 'title')}
										className='c-il-leaderboard__categories__ship-image c-il-leaderboard__show--desktop'
									/>
									<ScoreBreakdown
										showAlways={true}
										data={{
											...props,
											CriteriaScores: CriteriaBreakdown
										}}
									/>
								</div>
							}

						</div>
					</div>
				</AccordionItemPanel>
			</AccordionItem> : null}
	</>
		);
};

const ILLeaderboardAccordion = (props) => {
	const { LeaderBoard, Teams } = props;
	const { data: contentfulData } = useQuery({ queryKey: ['ILLeaderboardContentful'] });

	return (<section>
		<AccordionWrapper allowZeroExpanded className='c-il-leaderboard-accordions'>
			{LeaderBoard &&
				<>
					<LeaderboardHead />
					{LeaderBoard.map((leaderboardRow, index) =>
						<LeaderboardRow
							{...{
								Position: index + 1, ...leaderboardRow,
								Teams,
								contentfulData
							}}
							key={nanoid()}
						/>)}
				</>
			}
		</AccordionWrapper>
	</section>);
};

ILLeaderboardAccordion.propTypes = {
	props: PropTypes.shape({
		data: PropTypes.object,
		Leaderboard: PropTypes.array
	})
};

export default ILLeaderboardAccordion;
