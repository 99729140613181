import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Img } from '@sailgp/sailgp-shared-components';
import './Card.scss';
import { Cell, Grid, Row } from '../Grid';
import { IconClose } from '../Icons';

export default function Card(props) {
	const { data } = props;
	const [cardData, setCardData] = useState(data);
	const [maximizeCard,setMaximizeCard] = useState('ul-card-minimize');
	const panelRefs = useRef([]);
	const [panelHeight, setPanelHeight] = useState(0);
	useEffect(() => {
		setCardData(data);
	}, [data]);

	const handleCard = (event,index,contentfulId) => {
		setPanelHeight(panelRefs.current[index].scrollHeight);
		event.persist();

		setCardData(
			cardData &&
				cardData.map(card => {
					if (card.contentfulId === contentfulId) {
						setMaximizeCard('ul-card-maximize')
						return {
							...card,
							extendCardClassName: 'opencard'
						};
					} else {
						return {
							...card,
							extendCardClassName: 'hidecard'
						};
					}
				})
		);
	};
	const handleCardClose = event => {
		setPanelHeight('auto');
		const { id } = event.target.closest('li');
		const elementPos = document.querySelector('.categories');
		if (elementPos) {
			elementPos.scrollIntoView({
				behavior: 'smooth'
			});
		}
		setTimeout(() => {
			setCardData(
				cardData &&
					cardData.map(card => {
						if (card.title === id) {
							setMaximizeCard('ul-card-minimize')
							return {
								...card,
								extendCardClassName: 'closeselectedcard'
							};
						} else {
							return {
								...card,
								extendCardClassName: ''
							};
						}
					})
			);
		});
	};

	return <div className="card">
		<ul className={`card-content-list ${maximizeCard}`}
			style={{
				height: typeof panelHeight === 'number' && panelHeight > 0 ? `${panelHeight}px` : 'auto'
			}}
		>
			{cardData && cardData.map((card, index) => {
				const image =
								card && card.cardCriteriaSectionImage && card.cardCriteriaSectionImage.file && card.cardCriteriaSectionImage.file.url
									? card.cardCriteriaSectionImage.file.url
									: null;
				const cardImage = card && card.image && card.image.file && card.image.file.url ? card.image.file.url : null;
				return (
					<li key={card.title}
						className={`card-list ${card.extendCardClassName ? card.extendCardClassName : ''}`}
					>
						<div  className="card-info-container" >
							<div className="card-info">
								<div className="card-info-header">
									<span className="card-number">{card.cardNumber}</span>
									{cardImage && <Img src={cardImage} imgAlt="card-image" className="card-image"/>}
								</div>
								<div className="card-info-title">
									<p className="card-desc">{card.description}</p>
									<p className="card-seemore">{card.seeMoreLabel}</p>
								</div>

								<button type='button' className='card-button' onClick={(event) => {
									handleCard(event, index, card.contentfulId);
								}}>
												Open to read more about {card.description}
								</button>
							</div>
							<div className={`card-maximize index-${index}`}
											 ref={(element) => panelRefs.current[index] = element}>
								<Grid className="card-maximize-section">
									<Row className="card-maximize-info">
										<Cell xlCols={6} lgCols={6} smCols={2} mdCols={3} className="card-maximize-left-section">
											<p className="card-number">{card.cardNumber}</p>
											<p className="card-desc">{card.description}</p>
											<div className="card-maximize-content">
												<h3 className="card-heading">{card.cardHeader}</h3>
												<p className="card-para">{card.cardSectionContentOne}</p>
												<p className="card-content">{card.cardSectionContentTwo}</p>
												<div className="score-title">
													<p className="card-citeria-header">{card.cardCriteriaHeader}</p>
												</div>
												<ul className="card-maximize-section-list">
													{card.cardScores &&
																	card.cardScores.map(scores => {
																		return (
																			<li key={scores.label}>
																				<label>{scores.label}</label>
																			</li>
																		);
																	})}
												</ul>
											</div>
										</Cell>
										<Cell xlCols={6} lgCols={6} smCols={2} mdCols={3} className="card-maximize-right-section">
											{image && (
												<div className="card-maximize-image">
													<Img src={image} imgAlt="criteria-image" />
												</div>
											)}
											<div className="card-maximize-content">
												<p className='card-maximize-content-heading'><strong>{card.cardCriteriaHeaderTwo}</strong></p>
												<p className='card-maximize-content-desc'>{card.cardSectionContentThree}</p>
											</div>
											<button type="button" className="close-card" onClick={handleCardClose}>
												{card.closeLabel}
												<IconClose />
											</button>
										</Cell>
									</Row>
								</Grid>
							</div>
						</div>
					</li>
				);
			})
			}
		</ul>
	</div>
}

Card.propTypes = {
	data: PropTypes.array
};

Card.defaultProps = {
	data: {}
};
