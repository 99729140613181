import fetch from 'isomorphic-fetch';
import { getDockAPIURL } from '../lib/content-api-utils';
import { event } from '../lib/analytics';
import encryptString from './encryptString';
import logger from '../lib/logger';

const apiURL = getDockAPIURL();

let configSettingPost  = {
    method: 'POST',
	credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
};

let configSettingGet  = {
    method: 'GET',
	credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
};

let configSettingVerifyToken  = {
    method: 'GET',
	credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json ',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
    },
};

let configSettingValidation  = {
    method: 'POST',
	credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
};

export async function submitLogin(formData,submitUrl) {

	configSettingValidation['body'] = JSON.stringify(formData);
	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingValidation);

		const json = await fetchResponse.json();

		if(json.status === 'success') {
			const encryptedEmail = await encryptString(formData.emailAddress)
			const analyticsDetails = {eventName:'login', category: 'user',  action: 'login', label: 'login', method: 'email', user_id: encryptedEmail}
			//track analytics
			event(analyticsDetails)
		}

		return json;
	} catch (error) {
		return error;
	}
}

export async function submitLogout(formData,submitUrl) {
	if (!formData) {
		formData = { successUrl: submitUrl };
	}
	configSettingValidation['body'] = JSON.stringify(formData);
	try {
		const fetchResponse = await fetch( apiURL + 'user/logout', configSettingValidation);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}

export async function submitUserDelete(submitUrl) {
	try {
		const fetchResponse = await fetch( apiURL +  submitUrl, configSettingGet);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {
		return error;
	}
}

export async function submitRegister(formData,submitUrl) {

    configSettingValidation['body'] = JSON.stringify(formData);
	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingValidation);

		const encryptedEmail = await encryptString(formData.emailAddress)
		const analyticsDetails = {eventName:'sign_up', category: 'user',  action: 'sign_up', label: 'sign_up', method: 'email', customer_email: encryptedEmail}
		//track analytics
		event(analyticsDetails)

		const json = await fetchResponse.json();
		return json;
	} catch (error) {
		return error;
	}
}

export async function submitDockForm(formData,submitUrl) {

    configSettingPost['body'] = JSON.stringify(formData);
	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingPost);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {
		return error;
	}
}

export async function submitDockFormNoBody(submitUrl) {

	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingGet);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {
		return error;
	}
}

export async function submitDockFormNoToken(formData,submitUrl) {

    configSettingValidation['body'] = JSON.stringify(formData);
	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingValidation);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}


export async function verifyAccessToken() {
	try {
		const fetchResponse = await fetch( apiURL + "user/verify", configSettingVerifyToken);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}

export async function setMobileToken(formData) {

	configSettingPost['body'] = JSON.stringify(formData);
	let submitUrl = "user/set-mobile-login";
	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingPost);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}

export async function ssoLogin() {

	configSettingPost['body'] = JSON.stringify({landingUrl : location.origin + location.pathname});
	let submitUrl = "user/sso-login";
	try {
		const fetchResponse = await fetch( apiURL + submitUrl, configSettingPost);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}

export async function clearLoginForMobile() {
	try {
		const fetchResponse = await fetch( apiURL + "user/clear-login-for-mobile", configSettingGet);
		const json = await fetchResponse.json();
		return json;
	} catch (error) {

		return error;
	}
}



export default{
    submitDockForm,
	submitDockFormNoBody,
    verifyAccessToken,
	submitLogin,
	submitRegister,
	setMobileToken,
	clearLoginForMobile,
	submitUserDelete,
	ssoLogin
}
