import { useQuery } from '@tanstack/react-query';
import fetchLeaderboardAPI from '../api/get-data';

export const useLeaderboardAPI = ({ season, event }) => useQuery({
	queryKey: ['ILLeaderboardAPI', { season, event }],
	queryFn: async () => {
		const data = await fetchLeaderboardAPI({ season, event });
		return {
			...data,
			EventsInSeason: [{
				'EventCode': 'season',
				'EventVenue': 'Season Total'
			}, ...data.EventsInSeason],
			CategoryItems: [{
				'CategoryCode': 'all',
				'CategoryName': 'All Categories',
				'TeamScores': data.LeaderBoard
			}, ...data.CategoryItems]
		};
	}
});
